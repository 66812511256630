export const liveResults = `result POS|PLAYER|R1|R2|R3|R4|TO PAR|FEDEXCUP PTS|OFFICIAL MONEY
1|Jon Rahm|-7|-3|+1|-3|-12|600.00|$3,240,000
T2|Phil Mickelson|-1|-3|+3|-7|-8|0.00|$1,584,000
T2|Brooks Koepka|-7|-5|+1|+3|-8|0.00|$1,584,000
T4|Jordan Spieth|-3|-2|+4|-6|-7|126.667|$744,000
T4|Patrick Reed|-1|-2|E|-4|-7|0.00|$744,000
T4|Russell Henley|+1|-5|-1|-2|-7|126.667|$744,000
T7|Cameron Young|-5|E|+3|-4|-6|97.00|$580,500
T7|Viktor Hovland|-7|+1|-2|+2|-6|97.00|$580,500
9|Sahith Theegala|+1|-2|+1|-5|-5|88.00|$522,000
T10|Scottie Scheffler|-4|+3|-1|-2|-4|74.75|$432,000
T10|Matt Fitzpatrick|-2|E|E|-2|-4|74.75|$432,000
T10|Xander Schauffele|-4|+2|-1|-1|-4|74.75|$432,000
T10|Collin Morikawa|-3|-3|+2|E|-4|74.75|$432,000
T14|Gary Woodland|-4|E|+1|E|-3|62.50|$333,000
T14|Patrick Cantlay|-1|-1|-4|+3|-3|62.50|$333,000
T16|Tom Kim|-2|E|+2|-2|-2|52.886|$261,000
T16|Sungjae Im|-1|+4|-5|E|-2|52.886|$261,000
T16|Joaquin Niemann|-1|-3|+2|E|-2|0.00|$261,000
T16|Justin Rose|-3|-1|+1|+1|-2|52.886|$261,000
T16|Shane Lowry|-4|E|+1|+1|-2|52.886|$261,000
T16|Sam Bennett(a)|-4|-4|+4|+2|-2|0.00|$0
T16|Hideki Matsuyama|-1|-2|-2|+3|-2|52.886|$261,000
T23|Keegan Bradley|-2|E|+2|-1|-1|42.122|$187,200
T23|Chris Kirk|-2|+2|E|-1|-1|42.122|$187,200
T23|K.H. Lee|+2|-5|+2|E|-1|42.122|$187,200
T26|Tony Finau|-3|+2|+1|E|E|36.833|$147,000
T26|Scott Stallings|-2|+5|-3|E|E|36.833|$147,000
T26|Ryan Fox|-2|-1|+2|+1|E|0.00|$147,000
T29|Si Woo Kim|+1|E|E|E|+1|30.883|$125,100
T29|Sam Burns|-4|-1|+6|E|+1|30.883|$125,100
T29|Harold Varner III|E|-1|+4|-2|+1|0.00|$125,100
T29|Mackenzie Hughes|+4|-3|+2|-2|+1|30.883|$125,100
33|Tommy Fleetwood|E|-1|+2|+2|+3|26.633|$111,600
T34|Tyrrell Hatton|-1|+1|E|+4|+4|22.667|$97,200
T34|Cameron Smith|-2|E|+3|+3|+4|0.00|$97,200
T34|Zach Johnson|+3|-2|+2|+1|+4|22.667|$97,200
T34|Talor Gooch|E|+2|+1|+1|+4|0.00|$97,200
T34|J.T. Poston|+2|E|+4|-2|+4|22.667|$97,200
T39|Taylor Moore|+1|E|-2|+6|+5|17.567|$79,200
T39|Abraham Ancer|E|-1|+2|+4|+5|0.00|$79,200
T39|Adam Scott|-4|+2|+5|+2|+5|17.567|$79,200
T39|Jason Day|-5|E|+2|+8|+5|17.567|$79,200
T43|Max Homa|-1|+1|E|+6|+6|13.60|$66,600
T43|Harris English|-1|-1|+5|+3|+6|13.60|$66,600
T43|Mito Pereira|+2|-2|+5|+1|+6|13.60|$66,600
T46|Sepp Straka|-2|+1|+2|+6|+7|11.617|$57,600
T46|Seamus Power|+1|E|+1|+5|+7|11.617|$57,600
T48|Thomas Pieters|+2|+1|E|+5|+8|0.00|$50,760
T48|Dustin Johnson|-1|E|+6|+3|+8|0.00|$50,760
T50|Charl Schwartzel|+2|+1|+1|+5|+9|0.00|$46,080
T50|Fred Couples|-1|+2|+4|+4|+9|9.35|$46,080
52|Billy Horschel|+1|+2|+2|+7|+12|8.50|$44,280
53|Keith Mitchell|+3|-1|+5|+7|+14|7.933|$43,200
CUT|Francesco Molinari|E|+4|||+4|0.00|$0
CUT|Bryson DeChambeau|+2|+2|||+4|0.00|$0
CUT|Tom Hoge|+2|+2|||+4|0.00|$0
CUT|Mike Weir|E|+4|||+4|0.00|$0
CUT|Justin Thomas|-2|+6|||+4|0.00|$0
CUT|Bernhard Langer|+3|+2|||+5|0.00|$0
CUT|Ben Carr(a)|+3|+2|||+5|0.00|$0
CUT|Rory McIlroy|E|+5|||+5|0.00|$0
CUT|Adrian Meronk|+1|+4|||+5|0.00|$0
CUT|Kevin Kisner|E|+5|||+5|0.00|$0
CUT|Cameron Champ|+4|+2|||+6|0.00|$0
CUT|Danny Willett|+3|+3|||+6|0.00|$0
CUT|Vijay Singh|+3|+3|||+6|0.00|$0
CUT|Min Woo Lee|+3|+3|||+6|0.00|$0
CUT|Kazuki Higa|+4|+2|||+6|0.00|$0
CUT|Aldrich Potgieter(a)|+5|+2|||+7|0.00|$0
CUT|Brian Harman|+5|+2|||+7|0.00|$0
CUT|Matthew McClean(a)|+5|+2|||+7|0.00|$0
CUT|Sergio Garcia|+2|+5|||+7|0.00|$0
CUT|Mateo Fernandez de Oliveira(a)|+4|+3|||+7|0.00|$0
CUT|Corey Conners|+1|+7|||+8|0.00|$0
CUT|Jason Kokrak|+1|+7|||+8|0.00|$0
CUT|Harrison Crowe(a)|+3|+5|||+8|0.00|$0
CUT|Kurt Kitayama|+3|+5|||+8|0.00|$0
CUT|Gordon Sargent(a)|+5|+4|||+9|0.00|$0
CUT|Alex Noren|+6|+3|||+9|0.00|$0
CUT|Bubba Watson|+5|+4|||+9|0.00|$0
CUT|José María Olazábal|+5|+5|||+10|0.00|$0
CUT|Adam Svensson|+3|+8|||+11|0.00|$0
CUT|Larry Mize|+7|+8|||+15|0.00|$0
CUT|Sandy Lyle|+9|+11|||+20|0.00|$0
W/D|Kevin Na|-||||NAN|0.00|$0
W/D|Will Zalatoris|-||||NAN|0.00|$0
W/D|Louis Oosthuizen|+4||||+4|0.00|$0
W/D|Tiger Woods|+2|+1|||+3|0.00|$0`;

export const rawMastersField = `
Ludvig Åberg
SWEDEN
Byeong Hun An
KOREA
Keegan Bradley
UNITED STATES
Sam Burns
UNITED STATES
Patrick Cantlay
UNITED STATES
Wyndham Clark
UNITED STATES
Eric Cole
UNITED STATES
Corey Conners
CANADA
Fred Couples
UNITED STATES
Cameron Davis
AUSTRALIA
Jason Day
AUSTRALIA
Bryson DeChambeau
UNITED STATES
Santiago de la Fuente (A)
MEXICO
Nick Dunlap
UNITED STATES
Austin Eckroat
UNITED STATES
Harris English
UNITED STATES
Tony Finau
UNITED STATES
Matt Fitzpatrick
ENGLAND
Tommy Fleetwood
ENGLAND
Rickie Fowler
UNITED STATES
Ryan Fox
NEW ZEALAND
Sergio Garcia
SPAIN
Lucas Glover
UNITED STATES
Emiliano Grillo
ARGENTINA
Adam Hadwin
CANADA
Stewart Hagestad (A)
UNITED STATES
Brian Harman
UNITED STATES
Tyrrell Hatton
ENGLAND
Russell Henley
UNITED STATES
Ryo Hisatsune
JAPAN
Lee Hodges
UNITED STATES
Nicolai Højgaard
DENMARK
Max Homa
UNITED STATES
Viktor Hovland
NORWAY
Sungjae Im
KOREA
Stephan Jaeger
GERMANY
Dustin Johnson
UNITED STATES
Zach Johnson
UNITED STATES
Si Woo Kim
KOREA
Tom Kim
KOREA
Chris Kirk
UNITED STATES
Kurt Kitayama
UNITED STATES
Jake Knapp
UNITED STATES
Brooks Koepka
UNITED STATES
Christo Lamprecht (A)
SOUTH AFRICA
Min Woo Lee
AUSTRALIA
Luke List
UNITED STATES
Shane Lowry
IRELAND
Peter Malnati
UNITED STATES
Hideki Matsuyama
JAPAN
Denny McCarthy
UNITED STATES
Rory McIlroy
NORTHERN IRELAND
Adrian Meronk
POLAND
Phil Mickelson
UNITED STATES
Taylor Moore
UNITED STATES
Collin Morikawa
UNITED STATES
Grayson Murray
UNITED STATES
Joaquín Niemann
CHILE
José María Olazábal
SPAIN
Thorbjørn Olesen
DENMARK
Matthieu Pavon
FRANCE
J.T. Poston
UNITED STATES
Jon Rahm
SPAIN
Patrick Reed
UNITED STATES
Justin Rose
ENGLAND
Xander Schauffele
UNITED STATES
Scottie Scheffler
UNITED STATES
Adam Schenk
UNITED STATES
Charl Schwartzel
SOUTH AFRICA
Adam Scott
AUSTRALIA
Neal Shipley (A)
UNITED STATES
Vijay Singh
FIJI
Cameron Smith
AUSTRALIA
Jordan Spieth
UNITED STATES
Sepp Straka
AUSTRIA
Jasper Stubbs (A)
AUSTRALIA
Nick Taylor
CANADA
Sahith Theegala
UNITED STATES
Justin Thomas
UNITED STATES
Erik van Rooyen
SOUTH AFRICA
Camilo Villegas
COLOMBIA
Bubba Watson
UNITED STATES
Mike Weir
CANADA
Danny Willett
ENGLAND
Gary Woodland
UNITED STATES
Tiger Woods
UNITED STATES
Cameron Young
UNITED STATES
Will Zalatoris
UNITED STATES
`;

export const mastersField = rawMastersField.split('\n')
  .filter((line, index) => line.length > 0 && index % 2 === 1);

export const rawRankings = `1       Scottie Scheffler
2       Rory McIlroy
3       Jon Rahm
4       Wyndham Clark
5       Xander Schauffele
6       Viktor Hovland
7       Patrick Cantlay
8       Brian Harman
9       Ludvig Åberg
10      Matt Fitzpatrick
11      Max Homa
12      Hideki Matsuyama
13      Tommy Fleetwood
14      Cameron Young
15      Sahith Theegala
16      Keegan Bradley
17      Russell Henley
18      Jordan Spieth
19      Tyrrell Hatton
20      Collin Morikawa
21      Jason Day
22      Sam Burns
23      Tom Kim
24      Nick Taylor
25      Matthieu Pavon
26      Tony Finau
27      Chris Kirk
28      Justin Thomas
29      Sepp Straka
30      Denny McCarthy
31      Will Zalatoris
32      Min Woo Lee
33      Brooks Koepka
34      Akshay Bhatia
35      Lucas Glover
36      Shane Lowry
37      Rickie Fowler
38      Nicolai Højgaard
39      Emiliano Grillo
40      Eric Cole
41      Sungjae Im
42      Stephan Jaeger
43      Kurt Kitayama
44      Byeong Hun An
45      Harris English
46      Adam Hadwin
47      J.T. Poston
48      Si Woo Kim
49      Corey Conners
50      Austin Eckroat
51      Adam Schenk
52      Jake Knapp
53      Adam Scott
54      Ryan Fox
55      Taylor Moore
56      Christiaan Bezuidenhout
57      Adrian Meronk
58      Justin Rose
59      Grayson Murray
60      Tom Hoge
61      Brendon Todd
62      Cam Davis
63      Erik van Rooyen
64      Mackenzie Hughes
65      Alex Noren
66      Thomas Detry
67      Luke List
68      Cameron Smith
69      Peter Malnati
70      Nick Dunlap
71      Andrew Putnam
72      Thorbjørn Olesen
73      Keith Mitchell
74      Lee Hodges
75      Mark Hubbard
76      Aaron Rai
77      Beau Hossler
78      Adam Svensson
79      Keita Nakajima
80      Robert MacIntyre
81      Rasmus Højgaard
82      Lucas Herbert
83      Billy Horschel
84      Sami Välimäki
85      Taylor Montgomery
86      Patrick Rodgers
87      Ryo Hisatsune
88      Rikuya Hoshino
89      K.H. Lee
90      Ben Griffin
91      Victor Perez
92      Jordan Smith
93      Joaquin Niemann
94      Thriston Lawrence
95      Matt Wallace
96      Matt Kuchar
97      Alexander Björk
98      Alejandro Tosti
99      Vincent Norrman
100     Maverick McNealy
101     Justin Suh
102     Sam Ryder
103     Taylor Pendrith
104     Jesper Svensson
105     Takumi Kanaya
106     Zander Lombard
107     Chan Kim
108     David Puig
109     Kevin Yu
110     Charley Hoffman
111     Nate Lashley
112     Patrick Reed
113     Séamus Power
114     Michael Kim
115     Chandler Phillips
116     Dean Burmester
117     Louis Oosthuizen
118     Pablo Larrazabal
119     Zac Blair
120     Davis Thompson
121     Jorge Campillo
122     Yannik Paul
123     Doug Ghim
124     Matti Schmid
125     S.H. Kim
126     Alex Smalley
127     Sam Stevens
128     Ben Silverman
129     Sebastian Söderberg
130     Carl Yuan
131     C.T. Pan
132     Ewen Ferguson
133     Ben Kohles
134     Joost Luiten
135     Gary Woodland
136     Chez Reavie
137     Max Greyserman
138     J.J. Spaun
139     Scott Stallings
140     Adrian Otaegui
141     Antoine Rozner
142     Tom McKibbin
143     Romain Langasque
144     Connor Syme
145     Rico Hoey
146     Brandon Wu
147     Alex Fitzpatrick
148     David Skinns
149     Taiga Semikawa
150     Justin Lower
151     Andrew Novak
152     Tyler Duncan
153     Martin Laird
154     Dylan Wu
155     Callum Shinkwin
155     Hayden Buckley
157     Ryan Moore
158     Joel Dahmen
159     Mason Andersen
160     Joe Highsmith
161     Jimmy Stanger
162     David Lingmerth
163     Grant Forrest
164     Nathan Kimsey
165     Camilo Villegas
166     Phil Mickelson
167     Chesson Hadley
168     Carson Young
169     Daniel Hillier
170     Robby Shelton
171     Marcel Siem
172     Nick Hardy
173     Matteo Manassero
174     Richard Mansell
175     Mito Pereira
176     Garrick Higgo
177     Kevin Streelman
178     Ben Martin
179     Harry Hall
180     Brice Garnett
181     Julien Guerrier
182     Norman Xiong
183     Greyson Sigg
184     Parker Coody
185     Dan Bradbury
186     Jeff Winther
187     Matt NeSmith
188     Peter Kuest
189     Shubhankar Sharma
190     Matthew Southgate
191     Aaron Baddeley
192     Nicholas Lindheim
193     Erik Barnes
194     Jacob Bridgeman
195     Calum Hill
196     Adrien Dumont de Chassart
197     Sam Bairstow
198     Taylor Dickson
199     Joseph Bramlett
200     Pierceson Coody`;
