import React, { useContext } from 'react';
import { isAlive, regionPaths } from './shared/helpers';
import { GamePick } from './shared/sharedTypes';
import { TeamsContext } from './TeamsContext';

export function teamsForGame(gameIndex: number) {
  return regionPaths.reduce((teams, current, currentIndex) => {
    if (current.includes(gameIndex)) {
      return [...teams, currentIndex];
    }
    return teams;
  }, []);
}

export default function Games(props: {
  onGameChange: React.ChangeEventHandler<HTMLSelectElement>;
  games: GamePick[]; // games to display in this component
  results?: GamePick[]; // results, if undefined, compare to picks,
  picks: GamePick[]; // all picks
}) {
  const {
    results, picks, onGameChange, games
  } = props;

  const teamsInfo = useContext(TeamsContext);

  let renderedGames;
  if (results) {
    // there are results so just show how this bracket looks compared to that
    renderedGames = games.map((gamePick, index) => {
      const result = results[gamePick.gameIndex];

      let resultClass = '';
      if (result.pickIndex === -1) {
        // game is yet to be determined
        resultClass = isAlive(gamePick.pickIndex, gamePick.gameIndex, results) ? '' : ' dead';
      } else {
        resultClass = result.pickIndex === gamePick.pickIndex ? ' correct' : ' dead';
      }

      return (
        <div className={`game-${index} game${resultClass}`} key={`game-div-${gamePick.gameIndex}`}>
          {teamsInfo?.teams ? teamsInfo.teams[gamePick.pickIndex] : 'loading'}
        </div>
      );
    });
  } else {
    renderedGames = games.map((gamePick, index) => {
      const gameTeams = teamsForGame(gamePick.gameIndex)
        .map((teamIndex) => ({
          teamIndex,
          teamDisplayName: teamsInfo?.teams ? teamsInfo.teams[teamIndex] : 'unknown'
        }))
        .filter((gameTeam) => isAlive(
          gameTeam.teamIndex,
          gamePick.gameIndex,
          picks // it's picks because there are no results so that is the source of truth
        ));

      gameTeams.unshift({
        teamIndex: -1,
        teamDisplayName: '-'
      });

      const currentPick = gameTeams.find((info) => gamePick.pickIndex === info.teamIndex);

      if (!currentPick) {
        throw new Error('no pick!');
      }

      const currentValue = `${gamePick.gameIndex}.${currentPick.teamIndex}`;

      return (
        <select
          className={`game-${index} game`}
          onChange={onGameChange}
          key={`game-select-${gamePick.gameIndex}`}
          id={`game-select-${gamePick.gameIndex}`}
          value={currentValue}
        >
          {gameTeams.map((gameTeam) => (
            <option
              value={`${gamePick.gameIndex}.${gameTeam.teamIndex}`}
              key={`game-${gamePick.gameIndex}-team-${gameTeam.teamIndex}`}
              id={`game-${gamePick.gameIndex}-team-${gameTeam.teamIndex}`}
            >
              {gameTeam.teamDisplayName}
            </option>
          ))}
        </select>
      );
    });
  }

  return (
    // div would mess up CSS
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { renderedGames }
    </>
  );
}

Games.defaultProps = {
  results: undefined
};
