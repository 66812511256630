import React, { useEffect, useState } from 'react';
import { fetchContestants } from './Scoreboard';
import serverCall from './serverCall';
import Contestant from './shared/Contestant';

export default function UpdatePaid() {
  const [contestants, setContestants] = useState<Contestant[] | undefined>(undefined);

  useEffect(() => {
    fetchContestants().then((updatedContestants) => {
      setContestants(updatedContestants);
    });
  });

  function createOnChange(contestantId: string) {
    return async (event: React.ChangeEvent<HTMLInputElement>) => {
      await serverCall('updatePaid', { id: contestantId, paid: event.target.checked });
      const newContestants = await fetchContestants();
      setContestants(newContestants);
    };
  }

  if (!contestants) {
    return 'Working on retrieving contestants...';
  }

  return (
    <div>
      {contestants.map((contestant) => (
        <div>
          <input
            type="checkbox"
            id={contestant.id}
            name={contestant.name}
            checked={contestant.paid}
            onChange={createOnChange(contestant.id)}
          />
          <label htmlFor={contestant.id}>{contestant.name}</label>
        </div>
      ))}
    </div>
  );
}
