import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { Link, useParams } from 'react-router-dom';
import Bracket from './Bracket';
import useResults from './hooks/useResults';
import Contestant from './shared/Contestant';

import './viewBracket.css';
import { Loading } from './Loading';

export default function ViewBracket() {
  const results = useResults();
  const { contestantId } = useParams();

  const [picks, setPicks] = useState<number[] | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);

  const [scale, setScale] = useState<number>(1);
  const viewBracketRef = useRef<HTMLDivElement | null>(null);

  // this works better because updating refs doesn't trigger
  const handleResize = useCallback((node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }
    viewBracketRef.current = (node);
    const newScale = window.innerWidth / node.scrollWidth;
    setScale(Math.max(newScale, 0.5));
  }, []);

  useEffect(() => {
    function resizeListener() {
      if (viewBracketRef.current) {
        const newScale = window.innerWidth / viewBracketRef.current.scrollWidth;
        setScale(Math.max(newScale, 0.5));
      }
    }

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/getPicks`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: contestantId
      })
    }).then((response) => {
      response.json().then((json) => {
        setPicks(json.picks);
        setName(json.name);
      });
    });
  }, [contestantId]);

  const onUpdate = useCallback(() => {
    // no-op since we're just viewing bracket
  }, []);

  if (!picks || !contestantId || !results) {
    return (
      <Loading />
    );
  }

  // create dummy contestant to get score
  // is there a better way?
  const contestant = new Contestant('', '', '', picks);

  if (picks.some((pick) => pick === -1)) {
    return (
      <div>
        Bracket is not complete!
      </div>
    );
  }

  return (
    <>
      <div className="view-bracket-header">
        <Link to="/scoreboard">Scoreboard</Link>
        <h2>{ name }</h2>
        <div className="score">
          Score:
          {' '}
          { contestant.scores(results).points }
          {' '}
          (
          { contestant.scores(results).potential }
          )
        </div>
      </div>
      <div className="view-bracket-header placeholder" />
      <div className="view-bracket entireBracket" style={{ scale: `${scale}` }} ref={handleResize}>
        <Bracket
          rawPicks={picks}
          rawResults={results}
          onUpdate={onUpdate}
        />
      </div>
    </>
  );
}
