/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';
import { DiscordChannel, postToDiscord } from './discord';
import { rawRankings, mastersField } from './liveResults';

const unranked = mastersField.filter((name) => !rawRankings.includes(name));
const ranked = mastersField.filter((name) => rawRankings.includes(name));

const rankedMastersField = ranked.map((name) => {
  const worldRank = rawRankings.indexOf(name);
  return {
    name,
    worldRank
  };
});

rankedMastersField.sort((a, b) => a.worldRank - b.worldRank);

export const golfersInWorldRanking = rankedMastersField.map((golfer, index) => ({
  name: golfer.name,
  rank: index + 1
}));

const unrankedGolfers = unranked.map((name, index) => ({
  name,
  rank: golfersInWorldRanking.length + index + 1
}));

golfersInWorldRanking.push(...unrankedGolfers);

// unranked could be last tier?

const pickCt = 6;
export const useCt = 4;

// just make the tier sizes from the ranked golfers
// we'll place the unranked in their own tier
const tierSize = Math.floor(ranked.length / (pickCt - 1));

function rankingToTier(rank: number) {
  if (rank > ranked.length) {
    // actually unranked
    return pickCt;
  }
  return Math.min(Math.ceil(rank / tierSize), (pickCt - 1));
}

function startsTier(rank: number) {
  return rankingToTier(rank - 1) !== rankingToTier(rank);
}

export function Masters() {
  const [picks, setPicks] = React.useState<(number | undefined)[]>(Array(pickCt).fill(undefined));
  const [name, setName] = React.useState<string>('');
  const [submitted, setSubmitted] = React.useState<boolean>(false);

  const onClickMaker = useCallback((rank: number) => () => {
    const updatedPicks = [...picks];
    updatedPicks[rankingToTier(rank) - 1] = rank;
    setPicks(updatedPicks);
  }, [picks]);

  const picksComplete = picks.every((pick) => pick !== undefined) && name.length > 0;

  const onSubmit = useCallback(() => {
    const picksJSON = JSON.stringify({
      picks,
      name
    });
    setSubmitted(true);
    postToDiscord(`🎉 New contestant!:\n\n\`\`\`\n${picksJSON}\n\`\`\``, DiscordChannel.BOT_TESTING);
  }, [name, picks]);

  const onNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  if (submitted) {
    return (
      <>
        <div>
          Picks submitted! Thank you for entering!
        </div>
        <div>
          Your team:
        </div>
        {picks.map((pick) => (
          <div key={pick}>
            {pick && golfersInWorldRanking[pick - 1].name}
          </div>
        ))}
      </>
    );
  }

  return (
    <div style={{ margin: '1em' }}>
      <h1>{'Masters Pick \'em'}</h1>
      <p>
        Instructions:
        Choose one golfer from each tier.
        The scores of the top 4 golfers you choose will be added together.
        If a golfer misses, the cut, their score will be assumed to be 80.
      </p>
      <button type="button" disabled={!picksComplete} onClick={onSubmit}>Submit</button>
      <h2>Name</h2>
      <input type="text" value={name} onChange={onNameChange} />
      <h2>Your Picks</h2>
      <ul>
        {picks.map((pick) => (
          <li key={pick}>
            {pick && golfersInWorldRanking[pick - 1].name}
          </li>
        ))}
      </ul>
      <h2> Golfers in World Ranking Order </h2>
      <ul>
        {golfersInWorldRanking.map((golfer) => (
          <>
            {startsTier(golfer.rank) && (
            <li
              style={{
                marginTop: '10px',
                listStyleType: 'none',
                fontWeight: 'bold'
              }}
            >
              {`Tier ${rankingToTier(golfer.rank)}`}
              {rankingToTier(golfer.rank) === pickCt && ' (Unranked)'}
            </li>
            )}
            <li
              key={golfer.rank}
              onClick={onClickMaker(golfer.rank)}
              style={{
                border: 'solid 1px black',
                backgroundColor: picks.includes(golfer.rank) ? 'lightblue' : 'white'
              }}
            >
              {golfer.name}
              {' '}
              (
              {golfer.rank}
              )
            </li>
          </>
        ))}
      </ul>
    </div>
  );
}
