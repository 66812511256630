import React, { useContext, useEffect } from 'react';
import serverCall from './serverCall';
import { TeamsContext } from './TeamsContext';

export function tournamentOrderToSeed(tournamentOrder: number) {
  const positionInRegion = tournamentOrder % 16;
  return [
    1, 16, 8, 9, 5, 12, 4, 13, 6, 11, 3, 14, 7, 10, 2, 15
  ][positionInRegion];
}

export function Teams() {
  const teamInputs = [];
  const serverInfo = useContext(TeamsContext);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);
    const teams = [];
    for (let i = 0; i < 64; i += 1) {
      teams.push(`${formData.get(`team-${i}`)} (${tournamentOrderToSeed(i)})`);
    }

    const regions = [
            formData.get('northwest') as string,
            formData.get('southwest') as string,
            formData.get('northeast') as string,
            formData.get('southeast') as string
    ];

    serverCall('setTeams', { teams, regions });
  };

  for (let i = 0; i < 64; i += 1) {
    if (i === 0) {
      teamInputs.push(<h2>Region 1</h2>);
    } else if (i === 16) {
      teamInputs.push(<h2>Region 2</h2>);
    } else if (i === 32) {
      teamInputs.push(<h2>Region 3</h2>);
    } else if (i === 48) {
      teamInputs.push(<h2>Region 4</h2>);
    }
    teamInputs.push(
      <div style={{ width: 'auto', display: 'flex' }}>
        <label style={{ width: '100px' }} htmlFor={`team-${i}`}>
          Team
          {' '}
          {i + 1}
          {' '}
          (
          {tournamentOrderToSeed(i)}
          )
        </label>
        <input id={`team-${i}`} key={i} type="text" name={`team-${i}`} />
      </div>
    );
  }

  useEffect(() => {
    for (let i = 0; i < 64; i += 1) {
      const input = document.getElementById(`team-${i}`) as HTMLInputElement;
      if (input && serverInfo) {
        // strip off number because it's added later
        input.value = serverInfo.teams[i].replace(/\s\(\d+\)/, '');
      }
    }

    const northwest = document.getElementById('northwest') as HTMLInputElement;
    const southwest = document.getElementById('southwest') as HTMLInputElement;
    const northeast = document.getElementById('northeast') as HTMLInputElement;
    const southeast = document.getElementById('southeast') as HTMLInputElement;
    northwest.value = serverInfo?.regions[0] ?? '';
    southwest.value = serverInfo?.regions[1] ?? '';
    northeast.value = serverInfo?.regions[2] ?? '';
    southeast.value = serverInfo?.regions[3] ?? '';
  }, [serverInfo]);

  return (
    <form onSubmit={onSubmit} style={{ width: '80%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '500px' }}>
        Teams in tournament order
        {teamInputs}
        <div style={{ width: 'auto', display: 'flex' }}>
          <label style={{ width: '100px' }} htmlFor="northwest">
            Northwest Region
            <input id="northwest" key="northwest" type="text" name="northwest" />
          </label>
        </div>
        <div style={{ width: 'auto', display: 'flex' }}>
          <label style={{ width: '100px' }} htmlFor="southwest">
            Southwest Region
            <input id="southwest" key="southwest" type="text" name="southwest" />
          </label>
        </div>
        <div style={{ width: 'auto', display: 'flex' }}>
          <label style={{ width: '100px' }} htmlFor="northeast">
            Northeast Region
            <input id="northeast" key="northeast" type="text" name="northeast" />
          </label>
        </div>
        <div style={{ width: 'auto', display: 'flex' }}>
          <label style={{ width: '100px' }} htmlFor="southeast">
            Southeast Region
            <input id="southeast" key="southeast" type="text" name="southeast" />
          </label>
        </div>
        <input type="submit" value="Submit" />
      </div>
    </form>
  );
}
