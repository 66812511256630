import React, { useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';

export const AUTHENTICATED_COOKIE = '302_authenticated';
// TODO allow way to set password
const AUTHENTICATION_PASSWORD = 'payday-nape-lambskin';

export function isAdmin() {
  return Cookie.get(AUTHENTICATED_COOKIE) === 'true';
}

export default function Admin() {
  const [authenticated, setAuthenticated] = useState<boolean>(isAdmin());
  const [mastersLoading, setMastersLoading] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const onClick = useCallback(() => {
    if (inputRef.current?.value === AUTHENTICATION_PASSWORD) {
      Cookie.set(AUTHENTICATED_COOKIE, 'true');
      setAuthenticated(true);
    }
  }, []);

  const reloadMasters = useCallback(async () => {
    setMastersLoading(true);
    const result = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/updateMastersResults`);
    setMastersLoading(false);
    console.log('result', await result.json());
  }, []);

  if (!authenticated) {
    return (
      <>
        <input type="password" ref={inputRef} data-test-id="admin-password-input" />
        <button type="button" onClick={onClick} data-test-id="admin-password-submit-button">Submit</button>
      </>
    );
  }

  return (
    <center>
      <h1>The 302 Tournament</h1>
      <br />
      TODO: Button to start tourney
      <br />
      <Link to="/update" data-test-id="admin-update-results-link">Update Results</Link>
      <br />
      <Link to="/updatePaid">Update Paid</Link>
      <br />
      <Link to="/contestants">All Contestants</Link>
      <br />
      <Link to="/teams">Teams</Link>
      <br />
      <Link to="/">Home</Link>
      <br />
      <button
        type="button"
        onClick={reloadMasters}
        disabled={mastersLoading}
      >
        Reload Masters
      </button>
    </center>
  );
}
