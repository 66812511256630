import React, {
  useState, useEffect, useContext, ReactElement
} from 'react';
import { Link } from 'react-router-dom';
import ashamed from './images/ashamed.gif';
import praise from './images/praise.gif';
import Contestant from './shared/Contestant';
import useResults from './hooks/useResults';
import { TeamsContext } from './TeamsContext';
import { Loading } from './Loading';
import {
  isAlive, rawResultsToGamePicks
} from './shared/helpers';

export async function fetchContestants() {
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_ADDRESS}/getContestants?year=${process.env.REACT_APP_YEAR}`
  );
  const json = await response.json();
  // FIXME use server call here to get return type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return json.contestants.map((rawContestant: any) => new Contestant(
    rawContestant.name,
    rawContestant.id,
    rawContestant.secretId,
    rawContestant.picks,
    rawContestant.paid
  ));
}

// FIXME this is a pointless hook because it doesn't update...
export function useContestants() {
  const [contestants, setContestants] = useState<Contestant[] | undefined>(undefined);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER_ADDRESS}/getContestants?year=${process.env.REACT_APP_YEAR}`
    ).then((response) => {
      response.json().then((json) => {
        // FIXME use server call here to get return type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setContestants(json.contestants.map((rawContestant: any) => new Contestant(
          rawContestant.name,
          rawContestant.id,
          rawContestant.secretId,
          rawContestant.picks,
          rawContestant.paid
        )));
      });
    });
  }, []);

  return contestants;
}

export default function Scoreboard() {
  const contestants = useContestants();
  const teamsInfo = useContext(TeamsContext);
  const results = useResults();

  const hoverRef = React.createRef<HTMLDivElement>();
  const tableRef = React.createRef<HTMLTableElement>();

  const [hoverInfo, setHoverInfo] = useState<ReactElement>();

  if (!results || !teamsInfo?.teams || !contestants) {
    return <Loading />;
  }

  const onMouseEnter = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    if (!hoverRef.current || !tableRef.current) {
      return;
    }

    // show hover
    hoverRef.current.style.display = 'block';

    const { target } = event;

    if (!(target instanceof HTMLElement)) {
      setHoverInfo(<div />);
      return;
    }

    let contestantId: string | undefined = target.dataset.contestant;
    let currentTarget = target;
    while (!contestantId && currentTarget) {
      const { parentNode } = currentTarget;

      if (!(parentNode instanceof HTMLElement)) {
        setHoverInfo(<div />);
        return;
      }

      currentTarget = parentNode;

      if (currentTarget instanceof HTMLTableElement) {
        // we've moved too far up the tree just bail
        console.error('could not find contestant id');
        setHoverInfo(<div />);
        return;
      }

      contestantId = parentNode?.dataset.contestant;
    }

    const contestant = contestants.find((c) => c.id === contestantId);

    if (!contestant) {
      setHoverInfo(<div />);
      return;
    }

    const updatedHoverInfo = (
      <>
        <div style={{ fontWeight: 'bold' }}>
          {contestant.name}
        </div>
        <div style={{ textDecoration: 'underline', marginTop: '1em' }}>
          Final Two
        </div>
        {contestant.finalTwoResults(results).map((result) => {
          const teamName = teamsInfo.teams[result.teamIndex];
          return (
            <div>
              {result.decoration}
              {' '}
              {teamName}
            </div>
          );
        })}
        <div style={{ textDecoration: 'underline', marginTop: '1em' }}>
          Final Four
        </div>
        {contestant.finalFourResults(results).map((result) => {
          const teamName = teamsInfo.teams[result.teamIndex];
          return (
            <div>
              {result.decoration}
              {' '}
              {teamName}
            </div>
          );
        })}
      </>
    );

    setHoverInfo(updatedHoverInfo);

    const top = currentTarget.getBoundingClientRect().top + window.scrollY;
    hoverRef.current.style.top = `${top}px`;
    const tableRect = tableRef.current.getBoundingClientRect();
    const tableEdge = tableRect.left + tableRect.width;
    const hoverEdge = Math.min(window.innerWidth - hoverRef.current.getBoundingClientRect().width, tableEdge);
    hoverRef.current.style.left = `${hoverEdge}px`;
  };
  const onMouseLeave = () => {
    if (!hoverRef.current) {
      return;
    }
    hoverRef.current.style.display = 'none';
  };

  const gamePicks = rawResultsToGamePicks(results);

  contestants.sort((a, b) => {
    if (b.scores(results).points === a.scores(results).points) {
      return b.scores(results).potential - a.scores(results).potential;
    }
    return b.scores(results).points - a.scores(results).points;
  });

  const validContestants = contestants.filter(
    (contestant) => contestant.picks.every((pick) => pick !== -1)
  );

  let rank = 1;
  const displayContestants = validContestants.map((contestant, index) => {
    const firstPlace = validContestants[0].scores(results).points === contestant.scores(results).points;

    const lastPlacePoints = validContestants[validContestants.length - 1].scores(results).points;
    const lastPlace = lastPlacePoints === contestant.scores(results).points;

    const winnerIndex = contestant.picks[62];
    let winnerAlive;

    if (!firstPlace
      && index - 1 >= 0 // just being careful here
      && contestant.scores(results).points !== validContestants[index - 1].scores(results).points) {
      rank = index + 1;
    }

    if (results[62] === -1) {
      // final game is yet to be determined so we can trust isAlive
      winnerAlive = isAlive(winnerIndex, 62, gamePicks);
    } else {
      // just compare results directly
      winnerAlive = results[62] === winnerIndex;
    }

    let potentialMet = false;
    if (contestant.scores(results).points === contestant.scores(results).potential) {
      potentialMet = true;
    }

    return (
      <tr
        key={`contestant-${contestant.id}`}
        data-contestant={contestant.id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <td>
          {firstPlace && <small>We come to praise you</small>}
          {firstPlace && <img src={praise} alt="" />}
          {lastPlace && <small>We are ashamed</small>}
          {lastPlace && <img src={ashamed} alt="" />}
        </td>
        <td>{rank}</td>
        <td>
          <Link
            to={`/view/${contestant.id}`}
          >
            {contestant.name}
          </Link>
        </td>
        <td style={{ textAlign: 'center', fontWeight: `${potentialMet ? 'bold' : 'regular'}` }}>
          {contestant.scores(results).points}
        </td>
        <td style={{ textAlign: 'center', fontWeight: `${potentialMet ? 'bold' : 'regular'}` }}>
          {contestant.scores(results).potential}
        </td>
        <td style={{ textAlign: 'center' }}>
          {contestant.finalFourResults(results).map((result) => result.decoration)}
        </td>
        <td style={{ textAlign: 'center' }}>
          {contestant.finalTwoResults(results).map((result) => result.decoration)}
        </td>
        <td style={{
          fontStyle: winnerAlive ? '' : 'italic',
          textDecoration: winnerAlive ? '' : 'line-through',
          textAlign: 'center'
        }}
        >
          {contestant.picks && teamsInfo.teams[contestant.picks[62]]}
        </td>
        <td style={{ textAlign: 'center' }}>{!contestant.paid && '*'}</td>
      </tr>
    );
  });

  return (
    <>
      <Link to="/">Home</Link>
      <div
        className="hover-info"
        ref={hoverRef}
        style={{
          display: 'none',
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '250px',
          backgroundColor: 'white',
          border: '1px solid black',
          borderRadius: '5px',
          padding: '5px'
        }}
      >
        {hoverInfo}
      </div>
      <center>
        <table data-test-id="scoreboard-table" ref={tableRef}>
          <tr>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td />
            <td> Rank</td>
            <td>Player</td>
            <td> Score -</td>
            <td> Potential Score -</td>
            <td>
              {' '}
              Final Four -
            </td>
            <td>
              {' '}
              Final Two -
            </td>
            <td>
              {' '}
              {'Nat\'l Champ -'}
            </td>
            <td> Not Paid</td>
          </tr>
          {displayContestants}
        </table>
      </center>
      <br />
      <br />
      <p>
        {'*italicized Nat\'l champions means they have lost'}
        <br />
        <br />
        {displayContestants.length}
        {' '}
        contestants entered.
      </p>
    </>
  );
}
