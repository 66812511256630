import React, { ReactElement, useCallback } from 'react';
import Bracket from './Bracket';
import useResults from './hooks/useResults';
import { Loading } from './Loading';
import { teamsForGame } from './Games';
import { isAlive, rawResultsToGamePicks } from './shared/helpers';
import { useContestants } from './Scoreboard';
import Contestant from './shared/Contestant';
import { tournamentOrderToSeed } from './Teams';

function simulateRestOfTournament(basePicks: number[], useSeedProbability = false) {
  const simulatedPicks = rawResultsToGamePicks(basePicks);
  basePicks.forEach((pick, index) => {
    if (pick === -1) {
      const teamsInfo = teamsForGame(index)
        .map((teamIndex) => ({
          teamIndex,
          teamDisplayName: ''
        }))
        .filter((teamInfo) => isAlive(
          teamInfo.teamIndex,
          index,
          simulatedPicks // it's simulatedPicks that is the source of truth here
        ));

      // teamsInfo should contain 1 or 2 teams
      // if 2, pick one at random
      // if 1, pick it
      if (teamsInfo.length === 2) {
        // TODO might be cool to allow user to customize pct changne of each team
        // winning
        // for Sweet 16 that would be 16*15/2 = 120 matchups but can default all to 50%
        // for 50/50 matchups we could make sure we hit all possibilities
        // then # of computations would be exponential with number of games remaining

        // or can base probability on seed difference?
        const team0Seed = tournamentOrderToSeed(teamsInfo[0].teamIndex);
        const team1Seed = tournamentOrderToSeed(teamsInfo[1].teamIndex);
        const seedDiff = team0Seed - team1Seed;

        let seed0WinPct = 0.5;

        if (useSeedProbability) {
          if (seedDiff > 10) {
            seed0WinPct = 0.7;
          } else if (seedDiff > 5) {
            seed0WinPct = 0.6;
          } else if (seedDiff < 10) {
            seed0WinPct = 0.3;
          } else if (seedDiff < 5) {
            seed0WinPct = 0.4;
          }
        }

        const seed0Win = Math.random() < seed0WinPct;

        simulatedPicks[index].pickIndex = teamsInfo[seed0Win ? 0 : 1].teamIndex;
      } else if (teamsInfo.length === 1) {
        simulatedPicks[index].pickIndex = teamsInfo[0].teamIndex;
      } else {
        console.error('no teams alive');
        throw new Error('no teams alive for simulation game!');
      }
    }
  });
  return simulatedPicks;
}

export function Simulation() {
  const results = useResults();
  const [simulationBase, setSimulationBase] = React.useState<number[]>([]);
  const contestants = useContestants();
  const [simulationResults, setSimulationResults] = React.useState<ReactElement | undefined>();
  const [simulating, setSimulating] = React.useState(false);

  if (results && simulationBase.length === 0) {
    // is there a better way to do this?
    // that is setting the initial state of simulationBase
    setSimulationBase(results);
  }

  const onUpdate = useCallback((updatedPicks: number[]) => {
    if (!results) {
      return;
    }
    // we don't want to update the actual results but anything else we'll factor into the simulation
    const resultsForSim = results?.map((result, index) => {
      if (result === -1) {
        return updatedPicks[index];
      }
      // TODO show error message to user?
      return result;
    });

    setSimulationBase(resultsForSim);
  }, [results]);

  const performSimulations = useCallback(async (useSeedProbs = false, paidOnly = false) => {
    const contestantResultsMap = new Map<string, {
        contestant: Contestant,
        totalPoints: number,
        numberOfWins: number,
        numberOfTop5Finishes: number,
        winningBracket: number[] | undefined
    }>();

    await new Promise<void>((resolve) => {
      // allow the main (UI) thread to show the processing message
      setTimeout((resolve), 5);
    });

    const numberOfSimulations = 10000;
    for (let i = 0; i < numberOfSimulations; i += 1) {
      const simulatedResults = simulateRestOfTournament(simulationBase, useSeedProbs);
      const simulatedRawResults = simulatedResults.map((result) => result.pickIndex);

      let contestantResults = contestants?.map((contestant) => ({
        contestant,
        score: contestant.scores(simulatedRawResults).points
      }));

      if (paidOnly) {
        // remove users that haven't paid
        contestantResults = contestantResults?.filter((contestant) => contestant.contestant.paid);
      }

      contestantResults?.sort((a, b) => b.score - a.score);

      // now add ranks
      let runningRank = 0;
      let runningPoints = -1;
      const contestantRankedResults = contestantResults?.map((contestant, index) => {
        if (contestant.score !== runningPoints) {
          runningRank = index + 1;
          runningPoints = contestant.score;
        }
        return {
          ...contestant,
          rank: runningRank
        };
      });

      contestantRankedResults?.forEach((contestant) => {
        let priorResults = contestantResultsMap.get(contestant.contestant.id);
        if (!priorResults) {
          priorResults = {
            contestant: contestant.contestant,
            totalPoints: 0,
            numberOfWins: 0,
            numberOfTop5Finishes: 0,
            winningBracket: undefined
          };
        }

        // TODO save winning bracket (maybe last one?)
        // so we can show it to the user
        // maybe clicking on a user shows it
        // could store national champion?

        let { winningBracket } = priorResults;
        if (contestant.rank === 1 && !winningBracket) {
          // this is the first winning bracket so we'll save it
          winningBracket = simulatedRawResults;
        }

        contestantResultsMap.set(contestant.contestant.id, {
          contestant: contestant.contestant,
          totalPoints: priorResults.totalPoints + contestant.score,
          numberOfWins: priorResults.numberOfWins + (contestant.rank === 1 ? 1 : 0),
          numberOfTop5Finishes: priorResults.numberOfTop5Finishes + (contestant.rank <= 5 ? 1 : 0),
          winningBracket
        });
      });
    }

    const processedResults: {
          contestant: Contestant,
          avgPoints: number,
          winPct: number,
          top5Pct: number,
          winningBracket: number[] | undefined
      }[] = [];
    contestantResultsMap.forEach((resultObject) => {
      processedResults.push({
        contestant: resultObject.contestant,
        avgPoints: resultObject.totalPoints / numberOfSimulations,
        winPct: (resultObject.numberOfWins / numberOfSimulations) * 100,
        top5Pct: (resultObject.numberOfTop5Finishes / numberOfSimulations) * 100,
        winningBracket: resultObject.winningBracket
      });
    });

    processedResults.sort((a, b) => b.winPct - a.winPct);

    setSimulationResults(
      <div>
        <table>
          <thead>
            <tr>
              <th>Contestant</th>
              <th>Win%</th>
              <th>Top 5%</th>
              <th>Avg Points</th>
            </tr>
          </thead>
          <tbody>
            {processedResults.map((result) => (
              <tr
                key={result.contestant.id}
                onClick={() => {
                  if (result.winningBracket) {
                    setSimulationBase(result.winningBracket);
                  } else {
                    console.error('no winning bracket found');
                  }
                }}
              >
                <td>{result.contestant.name}</td>
                <td>{result.winPct.toFixed(2)}</td>
                <td>{result.top5Pct.toFixed(2)}</td>
                <td>{result.avgPoints.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
    setSimulating(false);
  }, [contestants, simulationBase]);

  const simulate = useCallback(() => {
    setSimulating(true);
    setSimulationResults(undefined);
    // hand off to async call to do simulation
    // TODO put in a web worker, but is that possible with create react app
    performSimulations();
  }, [performSimulations]);

  const simulatePaid = useCallback(() => {
    setSimulating(true);
    setSimulationResults(undefined);
    // hand off to async call to do simulation
    // TODO put in a web worker, but is that possible with create react app
    performSimulations(false, true);
  }, [performSimulations]);

  const simulateWithSeedProbs = useCallback(() => {
    setSimulating(true);
    setSimulationResults(undefined);
    // hand off to async call to do simulation
    // TODO put in a web worker, but is that possible with create react app
    performSimulations(true);
  }, [performSimulations]);

  if (!results || !contestants) {
    return <Loading timeoutInMS={5 * 1000} />;
  }

  return (
    <div style={{ display: 'flex', width: 'max-content', minWidth: '500px' }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: 'max-content',
        minWidth: '500px',
        alignItems: 'center'
      }}
      >
        <button type="button" onClick={simulate} style={{ width: 'max-content' }}>Run Simulation</button>
        <button
          type="button"
          onClick={simulateWithSeedProbs}
          style={{ width: 'max-content' }}
        >
          Run Simulation with Seed Probs
        </button>
        <button
          type="button"
          onClick={simulatePaid}
          style={{ width: 'max-content' }}
        >
          Run Simulation For Paid Contestants
        </button>
        {simulationResults}
        {simulating && <div>Processing...</div>}
        {!simulationResults && !simulating
          && (
          <div style={{
            display: 'flex', flexDirection: 'column', margin: '1em', gap: '4px'
          }}
          >
            <div>Instructions</div>
            <div>
              1. Update the results to the right to assume the outcomes of some of the remaining games.
            </div>
            <div>
              {'2. Click \'Run Simulation\' to see the results of the simulation.'}
            </div>
          </div>
          )}
      </div>
      <Bracket
        rawPicks={simulationBase}
        onUpdate={onUpdate}
      />
    </div>
  );
}
