import React, { useCallback } from 'react';
import Bracket from './Bracket';
import useResults from './hooks/useResults';
import { Loading } from './Loading';

export default function UpdateResults() {
  const results = useResults();

  const onUpdate = useCallback(
    (updatedPicks: number[]) => {
      fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/updateResults?year=${process.env.REACT_APP_YEAR}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedPicks)
      }).then(() => {
        // eslint-disable-next-line no-console
        console.log('updateResults response received');
      });
    },
    []
  );

  if (!results) {
    return <Loading />;
  }

  return (
    <>
      <h1>Update Results</h1>
      <Bracket rawPicks={results} rawResults={undefined} onUpdate={onUpdate} />
    </>
  );
}
