import React, {
  useState, useEffect, useCallback, useMemo, useRef, useContext
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Bracket from './Bracket';
import Contestant from './shared/Contestant';
import { isAlive, rawResultsToGamePicks } from './shared/helpers';
import { useResultsObject } from './hooks/useResults';
import serverCall from './serverCall';
import { teamsForGame } from './Games';

import './viewBracket.css';
import { TeamsContext } from './TeamsContext';
import { DiscordChannel, postToDiscord } from './discord';
import { Loading } from './Loading';
import { ServerError } from './shared/responseTypes';
import { isAdmin } from './Admin';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export type ViewState = 'region1' | 'region2' | 'region3' | 'region4' | 'finalFour' | 'entireBracket';

export default function PickBracket() {
  const [contestant, setContestant] = useState<Contestant | undefined>(undefined);
  const [hasCompleted, setHasCompleted] = useState<boolean>(false);
  const [showCompletedOverlay, setShowCompletedOverlay] = useState<boolean>(false);

  const { secretId } = useParams();

  const complete = useMemo(() => contestant?.picks.every((pick) => pick !== -1), [contestant?.picks]);

  if (complete && !hasCompleted) {
    // should we show user a new screen with their whole bracket at this point?
    // with instructions on how to edit?
    setHasCompleted(true);

    setShowCompletedOverlay(true);

    postToDiscord(`🎉 ${contestant?.name} has completed their bracket!`, DiscordChannel.STATUS);
  }

  useEffect(() => {
    if (!secretId) {
      throw new Error('no id');
    }
    serverCall('getContestantBySecret', { secretId }).then((response) => {
      if (response.success) {
        // apparently picks can be undefined if they haven't made any picks yet
        const initialComplete = response.picks?.every((pick: number) => pick !== -1);

        if (initialComplete) {
          // if they start with a completed bracket,
          // don't show the overlay
          setHasCompleted(true);
        }

        setContestant(new Contestant(response.name, response.id, response.secretId, response.picks));
      } else {
        console.error('error', response.error);
      }
    });
  }, [secretId]);

  const navigate = useNavigate();

  const results = useResultsObject();

  const teamsAndRegionInfo = useContext(TeamsContext);

  const onUpdate = useCallback(
    (updatedPicks: number[]) => {
      if (!contestant) {
        throw new Error('Updating picks but no contestant!');
      }
      setContestant(
        new Contestant(contestant.name, contestant.id, contestant.secretId, updatedPicks)
      );

      // check if updated picks are complete
      const updateComplete = updatedPicks.every((pick) => pick !== -1);

      if (!updateComplete && hasCompleted) {
        console.warn('defering update because contestant has submitted a complete bracket in past');
        return;
      }

      if (!secretId) {
        console.error('no secretId');
        return;
      }

      serverCall('updateContestant', {
        secretId,
        picks: updatedPicks
      }).then((result) => {
        if (!result.success) {
          console.error('error updating picks', result.error);
          if (result.error === ServerError.TourneyStarted) {
            console.error('tourney started', 'redirect to view page');
            navigate(`/view/${contestant.id}`);
          } else {
            // TODO show better error message
            // eslint-disable-next-line no-alert
            window.alert('error communicating with server - please try again');
            // revert change locally
            setContestant(contestant);
          }
        } // else success else
      });
    },
    [contestant, hasCompleted, navigate, secretId]
  );

  // just for testing...
  const randomizeBracket = useCallback(() => {
    if (!contestant) {
      console.error('no contestant');
      return;
    }
    const updatedPicks = [...contestant.picks];
    const updatedGamePicks = rawResultsToGamePicks(updatedPicks);

    updatedPicks.forEach((pick, index) => {
      if (pick === -1) {
        const teamsInfo = teamsForGame(index)
          .map((teamIndex) => {
            const teamDisplayName = teamsAndRegionInfo?.teams ? [teamIndex] : 'unknown';
            return {
              teamIndex,
              teamDisplayName
            };
          })
          .filter((teamInfo) => isAlive(
            teamInfo.teamIndex,
            index,
            updatedGamePicks // it's picks because there are no results so that is the source of truth
          ));

        // teamsInfo should contain 1 or 2 teams
        // if 2, pick one at random
        // if 1, pick it
        if (teamsInfo.length === 2) {
          updatedPicks[index] = teamsInfo[Math.floor(Math.random() * 2)].teamIndex;
        } else if (teamsInfo.length === 1) {
          updatedPicks[index] = teamsInfo[0].teamIndex;
        } else {
          console.error('no teams alive');
        }
        updatedGamePicks[index] = rawResultsToGamePicks(updatedPicks)[index];
      }
    });

    onUpdate(updatedPicks);
  }, [contestant, onUpdate, teamsAndRegionInfo?.teams]);

  const region1Complete = useMemo(() => contestant?.picks.every(
    (pick, index) => pick !== -1 || index > 14 // 0-14
  ), [contestant]);
  const region2Complete = useMemo(() => contestant?.picks.every(
    (pick, index) => pick !== -1 || index <= 15 || index > 29 // 15-29
  ), [contestant]);
  const region3Complete = useMemo(() => contestant?.picks.every(
    (pick, index) => pick !== -1 || index < 30 || index > 44 // 30-44
  ), [contestant]);
  const region4Complete = useMemo(() => contestant?.picks.every(
    (pick, index) => pick !== -1 || index < 45 || index > 59 // 45-59
  ), [contestant]);
  const finalFourComplete = useMemo(() => contestant?.picks.every(
    (pick, index) => pick !== -1 || index < 60
  ), [contestant]);

  const previousComplete = usePrevious(complete);

  // could maybe just use hasCompleted?
  if (complete && !previousComplete) {
    // is called twice at the moment
    // useMemo maybe should happen after?
    // confetti?
  }

  const [viewState, setViewState] = useState<ViewState>('entireBracket');

  function regionClickMaker(state: ViewState) {
    return () => {
      setViewState(state);
    };
  }

  if (!contestant || !teamsAndRegionInfo?.teams || !results) {
    return (
      <Loading />
    );
  }

  const allowPicks = !results.started || isAdmin();
  if (!allowPicks) {
    // no reason to view the picks page if tourney has started
    navigate(`/view/${contestant.id}`);
  }

  const picksDone = contestant.picks.filter((pick) => pick !== -1).length;

  const chooserButtonStates: ViewState[] = [
    'region1', 'region2', 'region3', 'region4', 'finalFour', 'entireBracket'
  ];
  const viewStateToHumanReadable: {[key in ViewState]: string} = {
    region1: `Region 1 ${region1Complete ? '✅' : '❌'}`,
    region2: `Region 2 ${region2Complete ? '✅' : '❌'}`,
    region3: `Region 3 ${region3Complete ? '✅' : '❌'}`,
    region4: `Region 4 ${region4Complete ? '✅' : '❌'}`,
    finalFour: `Final Four ${finalFourComplete ? '✅' : '❌'}`,
    entireBracket: `Bracket ${complete ? '✅' : '❌'}`
  };

  const chooserButtons = chooserButtonStates.map((buttonName) => (
    <button
      type="button"
      className={viewState === buttonName ? 'selected' : ''}
      onClick={regionClickMaker(buttonName)}
    >
      {viewStateToHumanReadable[buttonName]}
    </button>
  ));

  return (
    <>
      <div className="view-bracket-header">
        {hasCompleted ? <Link to="/scoreboard">Scoreboard</Link> : <div />}
        <h2>{ contestant.name }</h2>
        <div className="progress">
          <div className="progress-bar">
            <div className="progress-bar-inner" style={{ width: `${(picksDone / 63) * 100}%` }} />
          </div>
          <div className="picks-count">
            Picks:
            {' '}
            {picksDone}
            /63
          </div>
        </div>
      </div>
      <div className="view-bracket-header placeholder" />
      <div className="region-chooser">
        {chooserButtons}
      </div>
      <div className="region-chooser placeholder" />
      <Bracket
        rawPicks={contestant.picks}
        rawResults={!allowPicks ? results?.games : undefined}
        onUpdate={onUpdate}
        viewState={viewState}
      >
        <div className="info hidden">
          <div>{contestant.name}</div>
          <div>
            Picks
            {' '}
            {picksDone}
            /63
          </div>
          <div>
            Progress Bar
          </div>
          <div>
            Region 1:
            {region1Complete ? ' ✅' : '❌'}
          </div>
          <div>
            Region 2:
            {region2Complete ? 'Complete ✅' : 'Incomplete'}
          </div>
          <div>
            Region 3:
            {region3Complete ? 'Complete ✅' : 'Incomplete'}
          </div>
          <div>
            Region 4:
            {region4Complete ? 'Complete ✅' : 'Incomplete'}
          </div>
          <div>
            Bracket:
            {complete ? 'Complete ✅' : 'Incomplete'}
          </div>
          {complete && (
          <span>
            {' '}
            <Link to="/scoreboard">Scoreboard</Link>
          </span>
          )}
          {!complete && <div>Make your picks!</div>}
        </div>
      </Bracket>
      {process.env.REACT_APP_ENVIRONMENT === 'debug'
      && (
      <button
        type="button"
        onClick={randomizeBracket}
      >
        Randomize
      </button>
      )}
      {showCompletedOverlay && (
      <div className="completed-overlay">
        <div className="completed-overlay-content">
          <div>
            🎉 Congratulations, your bracket is now complete! 🎉
          </div>
          <div>
            You can continue to update your picks via this url:
            {' '}
            <a href={document.location.href}>{document.location.href}</a>
          </div>
          <div>
            View the
            {' '}
            <Link to="/scoreboard">Scoreboard</Link>
          </div>
          <button type="button" onClick={() => setShowCompletedOverlay(false)}>Close</button>
        </div>
      </div>
      )}
    </>
  );
}
