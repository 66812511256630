import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MessageBoard } from './MessageBoard';
import { useResultsObject } from './hooks/useResults';
import { Loading } from './Loading';
import { TeamsContext } from './TeamsContext';

export default function Home() {
  const resultsObject = useResultsObject();
  const tourneyInfo = useContext(TeamsContext);

  if (!resultsObject) {
    return <Loading timeoutInMS={5 * 1000} />;
  }

  if (!resultsObject.ready) {
    return (
      <div className="home-main">
        <h1>{tourneyInfo?.title}</h1>
        <div>
          Starting soon...
        </div>
        <Loading timeoutInMS={0} />
      </div>
    );
  }

  return (
    <>
      <div className="home-main">
        <h1>{tourneyInfo?.title}</h1>
        <Link to="scoreboard" id="link-scoreboard">Scoreboard</Link>
        <Link to="track" id="link-track">Look Up Contestant By Team</Link>
        {!resultsObject?.started && <Link to="enter" id="link-enter_the_pool">Enter the Pool</Link>}
        {tourneyInfo?.announcement?.heading && (
        <div className="announcement">
          {tourneyInfo.announcement.heading}
          <p>{tourneyInfo.announcement.message}</p>
          <a href={tourneyInfo.announcement.link.href}>{tourneyInfo.announcement.link.text}</a>
        </div>
        )}
      </div>
      <Link to="report">Report issue</Link>
      <MessageBoard />
    </>
  );
}
