import {
  isAlive, isDead, rawResultsToGamePicks, regionPaths
} from './helpers';

export default class Contestant {
  readonly picks: number[];

  readonly name: string;

  readonly id: string; // public id used to view bracket, view/id

  // secret id used to update bracket pick/secretId, undefined means don't have permission to update
  readonly secretId: string | undefined;

  readonly paid: boolean;

  // year: number; // so db can just grab contestants from this year?

  // create id so have consistent way to look up?
  // needs to be unique
  // could come from server...?
  constructor(name: string, id: string, secretId: string | undefined, picks?: number[], paid?: boolean) {
    if (!picks) {
      this.picks = [];
      while (this.picks.length < 63) {
        this.picks.push(-1);
      }
    } else {
      this.picks = picks;
    }
    this.name = name;
    this.id = id;
    this.secretId = secretId;
    this.paid = !!paid;
  }

  get bracketComplete() {
    return this.picks.every((pick) => pick !== -1);
  }

  // eslint-disable-next-line class-methods-use-this
  update() {
    // send update to server
  }

  /*
  static async createFromSecret(secretId: string) {
    // need to fetch from server...
  }
  */

  static roundNumberToRoundName(roundNumber: number) {
    switch (roundNumber) {
      case 0:
        return ''; // not winning at all
      case 1:
        return 'Round of 32';
      case 2:
        return 'Sweet Sixteen';
      case 3:
        return 'Elite Eight';
      case 4:
        return 'Final Four';
      case 5:
        return 'Championship';
      case 6:
        return 'National Champion';
      default:
        return 'Invalid round number';
    }
  }

  // return how far this contestant picked this team to go
  roundForTeam(teamIndex: number) {
    const path = regionPaths[teamIndex];
    // map to array of teams that won the games in the path, then filter for the team we're looking for
    // the length is how many games they were picked to win
    return path.map((gameIndex) => this.picks[gameIndex]).filter((pick) => pick === teamIndex).length;
  }

  scores(results: number[]) {
    let pts = 0;
    let pot = 0;

    if (results?.length && results && this.bracketComplete) {
      for (let i = 0; i < results?.length; i += 1) {
        const round = regionPaths[this.picks[i]].indexOf(i) + 1;
        let increment = 1;
        if (round === 2) {
          increment = 2;
        } else if (round === 3) {
          increment = 3;
        } else if (round === 4) {
          increment = 5;
        } else if (round === 5) {
          increment = 8;
        } else if (round === 6) {
          increment = 13;
        }
        if (results[i] === this.picks[i]) {
          pts += increment;
          pot += increment;
        } else if (
          results[i] === -1
            && isAlive(this.picks[i], i, rawResultsToGamePicks(results))
        ) {
          pot += increment;
        }
      }
    }

    return { points: pts, potential: pot };
  }

  finalFourTeams() {
    const finalFour = [this.picks[14], this.picks[29], this.picks[44], this.picks[59]];
    return finalFour;
  }

  // probably should be extracted to roundResults and then take a round
  finalFourResults(results: number[]) {
    const teams = this.finalFourTeams();
    return teams.map((teamIndex) => {
      const finalFourIndex = 3;
      const path = regionPaths[teamIndex];
      const finalFourResult = results[path[finalFourIndex]];
      let decoration = '🤷';
      if (finalFourResult === teamIndex) {
        // this team made it
        decoration = '✅';
      } else if (isDead(results, teamIndex)) {
        // this team did not make it
        decoration = '❌';
      }
      // team is alive but not to FF yet
      // keep the ?
      return {
        teamIndex,
        decoration
      };
    });
  }

  finalTwoResults(results: number[]) {
    const teams = this.finalTwoTeams();
    return teams.map((teamIndex) => {
      const finalFourIndex = 4;
      const path = regionPaths[teamIndex];
      const finalFourResult = results[path[finalFourIndex]];
      let decoration = '🤷';
      if (finalFourResult === teamIndex) {
        // this team made it
        decoration = '✅';
      } else if (isDead(results, teamIndex)) {
        // this team did not make it
        decoration = '❌';
      }
      // team is alive but not to FF yet
      // keep the ?
      return {
        teamIndex,
        decoration
      };
    });
  }

  finalTwoTeams() {
    return [this.picks[60], this.picks[61]];
  }
}
