import React from 'react';

export function Champions() {
  return (
    <>
      <h1>Fantaslay Football</h1>
      <ul>
        <li>
          2023: Jeff Boerner - bored goats
        </li>
      </ul>
      <h1>Boosketball</h1>
      <ul>
        <li>
          {'2023-2024: Joel Berger - Joel\'s Amazing Team'}
        </li>
      </ul>
      <h1>{'302 Tourney - Men\'s'}</h1>
      <ul>
        <li>
          2024: Mitch Hruska
        </li>
        <li>
          2023: Conor Bresnan
        </li>
        <li>
          ...
        </li>
        <li>
          2003: Jeff Boerner
        </li>
        <li>
          2002: Malcolm Richards
        </li>
        <li>
          2001: Jeff Boerner
        </li>
      </ul>
      <h1>{'302 Tourney - Women\'s'}</h1>
      <ul>
        <li>
          2024: Joel Berger
        </li>
      </ul>
      <h1>Survivor Draft</h1>
      <ul>
        <li>
          S45: Hazel - Dee
        </li>
        <li>
          S44: Nora - YamYam
        </li>
        <li>
          S43: ??? - Gabler
        </li>
        <li>
          S42: Hazel - Maryanne
        </li>
      </ul>
      <h1>{'Survivor Weekly Pick\'em'}</h1>
      <ul>
        <li>
          S45: Karen Boerner
        </li>
        <li>
          S44: Karen Boerner
        </li>
      </ul>
    </>
  );
}
