import Contestant from './Contestant';

export type ContestantType = {
  name: string;
  id: string;
  picks: number[];
  secretId: string;
};
// is this just Contestant?

export type ServerCall =
  | 'getResults'
  | 'updateContestant'
  | 'createContestant'
  | 'getContestantBySecret'
  | 'getResults'
  | 'updateResults'
  | 'getContestants'
  | 'deleteContestant'
  | 'updatePaid'
  | 'setTeams'
  | 'getTeams';

export type ServerResponseSuccessType<T extends ServerCall> =
  T extends 'getResults'
    ? { success: true; started: boolean, ready: boolean, games: number[] }
    : T extends 'updateContestant'
    ? { success: true }
    : T extends 'createContestant'
    ? {
        success: true;
        name: string;
        id: string;
        secretId: string;
        picks: number[];
      }
    : T extends 'getContestantBySecret'
    ? {
        success: true;
        name: string;
        id: string;
        secretId: string;
        picks: number[];
      }
    : T extends 'updateResults'
    ? {
        success: true;
      }
    : T extends 'getContestants'
    ? {
        success: true;
        contestants: Contestant[];
      }
    : T extends 'deleteContestant'
    ? {
        success: true;
      }
    : T extends 'updatePaid'
    ? {
        success: true;
      }
    : T extends 'setTeams'
    ? {
        success: true;
      }
    : T extends 'getTeams'
    ? {
        success: true;
        teams: string[];
        regions: string[];
        title: string;
        announcement?: {
          heading: string;
          message: string;
          link: {
            href: string;
            text: string;
          }
        };
      }
    : never;

export type ServerResponseType<T extends ServerCall> =
  | ServerResponseSuccessType<T>
  | {
      success: false;
      error: string;
    };

export type ServerCallPayload<T extends ServerCall> = T extends 'getResults'
  ? undefined
  : T extends 'updateContestant'
  ? { secretId: string; picks: number[] }
  : T extends 'createContestant'
  ? { name: string }
  : T extends 'getContestantBySecret'
  ? { secretId: string }
  : T extends 'updateResults'
  ? number[]
  : T extends 'getContestants'
  ? undefined
  : T extends 'deleteContestant'
  ? { secretId: string }
  : T extends 'updatePaid'
  ? { id: string, paid: boolean }
  : T extends 'setTeams'
  ? { teams: string[], regions: string[] }
  : T extends 'getTeams'
  ? undefined
  : never;

export enum ServerError {
  TourneyStarted = 'Tourney has already started',
  NoContestant = 'No contestant found'
}
