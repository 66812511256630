import { GamePick } from './sharedTypes';

// for each region add 15
export const regionPaths = [
  [0, 8, 12, 14], // team 0 (1 seed)
  [0, 8, 12, 14], // team 1 (16 seed)
  [1, 8, 12, 14],
  [1, 8, 12, 14],
  [2, 9, 12, 14],
  [2, 9, 12, 14],
  [3, 9, 12, 14],
  [3, 9, 12, 14], // team 7 (starting with game 0)
  [4, 10, 13, 14],
  [4, 10, 13, 14],
  [5, 10, 13, 14],
  [5, 10, 13, 14],
  [6, 11, 13, 14],
  [6, 11, 13, 14],
  [7, 11, 13, 14],
  [7, 11, 13, 14]
];

// need paths for all 64 teams
while (regionPaths.length < 64) {
  const priorArray = regionPaths[regionPaths.length - 16];
  const newArray = priorArray.map((gameIndex) => gameIndex + 15); // add 15 to get to next region
  regionPaths.push(newArray);
}

// add final four paths
for (let i = 0; i < regionPaths.length; i += 1) {
  const path = regionPaths[i];
  if (i < 32) {
    // east teams play in semi-final game 61
    path.push(60);
  } else {
    // west teams play in semi-final game 61
    path.push(61);
  }
  // all teams play in championship
  path.push(62);
}

export function rawResultsToGamePicks(rawResults: number[]) {
  const results = rawResults.map((rawPick, index) => ({
    pickIndex: rawPick,
    gameIndex: index
  }));

  return results;
}

// this is useful for determining which teams
// are available for picking
// but care is needed when trying to determine
// if a team is still alive for a particular game
// perhaps a bool could be passed in to indicate
// the particular situation
// TODO change name to isAliveForGame
export function isAlive(
  teamIndex: number,
  gameIndex: number,
  results: GamePick[]
) {
  const pathForTeam = regionPaths[teamIndex];
  return pathForTeam.every((pathGameIndex) => {
    if (pathGameIndex >= gameIndex) {
      // game is later than this game
      return true;
    }

    // team must have won this game or game hasn't been played
    const result = results.find((potentialResult) => potentialResult.gameIndex === pathGameIndex);

    if (!result) {
      throw new Error(
        `result is undefined! teamIndex: ${teamIndex}, gameIndex: ${pathGameIndex}`
      );
    }

    return result.pickIndex === teamIndex || result.pickIndex === -1;
  });
}

// prior method is for if is alive for a particular game
// this is for the tourney
export function isDead(results: number[], teamIndex: number) {
  const path = regionPaths[teamIndex];
  return path.some(
    (gameIndex) => results[gameIndex] !== teamIndex && results[gameIndex] !== -1
  );
}
