import React, { useEffect } from 'react';
import Cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { AUTHENTICATED_COOKIE } from './Admin';

// wraps children in a component that checks if the user is authenticated
export default function Authenticate(props: { children: React.ReactNode }) {
  const alreadyAuthenticated = Cookie.get(AUTHENTICATED_COOKIE);

  const navigate = useNavigate();
  useEffect(() => {
    if (alreadyAuthenticated !== 'true') {
      console.warn('unauthenticated, directing to admin');
      navigate('/admin');
    }
  });

  return props.children;
}
