import React, { useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import ashamed from './images/ashamed.gif';
import { useResultsObject } from './hooks/useResults';
import serverCall from './serverCall';

import './enterPool.css';
import { DiscordChannel, postToDiscord } from './discord';

const initialPicks: number[] = [];
while (initialPicks.length < 63) {
  initialPicks.push(-1);
}

export default function EnterPool() {
  const [working, setWorking] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [secretId, setSecretId] = useState<undefined | string>(undefined);

  const inputRef = useRef<HTMLInputElement>(null);

  const resultsObject = useResultsObject();

  const onSubmit = useCallback(() => {
    if (!inputRef.current?.value) {
      setError('Please enter a name');
      return;
    }

    setWorking(true);
    // ask server if this is a new contestant for this year's pool
    serverCall('createContestant', { name: inputRef.current?.value }).then(
      (response) => {
        setWorking(false);
        if (response.success) {
          postToDiscord(`🎉 New contestant!: ${response.name}`, DiscordChannel.STATUS);
          setSecretId(response.secretId);
        } else {
          setError('Contestant already exists, enter a different name');
          console.error('contestant already exists, enter a different name');
        }
      }
    );
  }, []);

  if (resultsObject?.started) {
    return (
      <div className="enter-pool-container">
        <img src={ashamed} alt="ashamed" />
        <div>Sorry, the tournament has already started</div>
        <Link to="/scoreboard" id="link-scoreboard">Scoreboard</Link>
      </div>
    );
  }

  if (working) {
    return 'Working...';
  }

  if (secretId) {
    // since user has a secretId, user has entered the pool
    // show the rules
    // and a button to move onto the pick bracket
    return (
      <div className="entered-pool-info">
        <div>Thank you for entering the NCAA Tournament Pool!</div>
        <div>Rules</div>
        <div className="rules">
          { /* eslint-disable-next-line max-len */ }
          <div>1. Contestants are awarded points for every correct game they predict in each round. The points awarded are 1-2-3-5-8-13.</div>
          { /* eslint-disable-next-line max-len */ }
          <div>2.  All contestants are grouped together, but the ones who have not put forth money will have a mark of some kind indicating that point.</div>
          { /* eslint-disable-next-line max-len */ }
          <div>3.  Ties will be broken by the number of teams one has in the Championship Game.  If that is equal, then it will be how many teams in the Final Four, if that is equal the Elite Eight, etc.</div>
          { /* eslint-disable-next-line max-len */ }
          <div>4.  Trash-talking on the message board is mandatory for all contestants.</div>
          { /* eslint-disable-next-line max-len */ }
          <div>{'5.  On a contestant\'s progress bracket Yellow indicates a game successfully predicted, Brown indicates a failed prediction and no marking indicates it is yet to be determined.'}</div>
          <div>
            { /* eslint-disable-next-line max-len */ }
            6.  For those contestants who wish to compete for cash prizes, the entry fee is a mere $5. If you are unable to give me the money by the first day of games, send me an email saying you would like to pay. Give the $5 to me (Jeff) in person or via PayPal:
            <a target="_blank" href="https://paypal.me/jeffboerner" rel="noreferrer">https://paypal.me/jeffboerner</a>
          </div>
        </div>
        <Link to={`/pick/${secretId}`} data-test-id="rules-pick-link">Pick Bracket</Link>
      </div>
    );
  }

  // still need a constestant, prompt for name
  return (
    <div className="enter-pool-container">
      <div>Enter the 302 Tournament Pool</div>
      <input ref={inputRef} placeholder="Name" id="input-enter" />
      <button type="button" onClick={onSubmit} id="button-enter">Enter</button>
      <div className="error">{error && `Error: ${error}`}</div>
    </div>
  );
}
