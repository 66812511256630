import React, { useCallback, useContext } from 'react';
import Games from './Games';
import { GamePick } from './shared/sharedTypes';
import { TeamsContext } from './TeamsContext';
import { Loading } from './Loading';

export default function Finals(props: {
  picks: GamePick[]; // array of length 3
  results?: GamePick[]; // also array of length 3,
  onUpdate: (gameIndex: number, teamIndex: number) => void;
}) {
  const { picks, onUpdate, results } = props;

  const teamsinfo = useContext(TeamsContext);

  // duplicate in Region.tsx, should be consolidated
  const onGameChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const gameIndexString = event.target.value.split('.')[0];
      const teamIndexString = event.target.value.split('.')[1];

      const gameIndex = parseInt(gameIndexString, 10);
      const teamIndex = parseInt(teamIndexString, 10);

      onUpdate(gameIndex, teamIndex);
    },
    [onUpdate]
  );

  if (!teamsinfo?.teams) {
    return (
      <Loading />
    );
  }

  return (
    <div className="finals">
      <div className="nw-team">
        {teamsinfo.teams[picks[14].pickIndex]}
      </div>
      <div className="sw-team">
        {teamsinfo.teams[picks[29].pickIndex]}
      </div>
      <div className="ne-team">
        {teamsinfo.teams[picks[44].pickIndex]}
      </div>
      <div className="se-team">
        {teamsinfo.teams[picks[59].pickIndex]}
      </div>
      <div className="line-nw-to-game-60" />
      <div className="line-sw-to-game-60" />
      <div className="line-game-60-to-game-62" />
      <div className="line-ne-to-game-61" />
      <div className="line-se-to-game-61" />
      <div className="line-game-61-to-game-62" />
      <Games
        games={picks.slice(60, 63)}
        picks={picks}
        results={results}
        onGameChange={onGameChange}
      />
      <div className="divider-sw-v" />
      <div className="title">
        Final Four
      </div>
      <div className="champion">
        Champion
      </div>
    </div>
  );
}

Finals.defaultProps = {
  results: undefined
};
