import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { MessageSubmit } from './MessageSubmit';
import { DiscordChannel, postToDiscord } from './discord';

export function ReportIssue() {
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const onSubmit = (name: string, message: string) => {
    postToDiscord(`Issue reported by ${name}: ${message}`, DiscordChannel.REPORT_ISSUE);
    setMessageSent(true);
  };
  return (
    <>
      {!messageSent && (
        <>
          <h1>Report Issue</h1>
          <MessageSubmit onSubmit={onSubmit} />
        </>
      )}
      {messageSent && (
        <>
          <h1>Thank you</h1>
          <Link to="/">Back to home</Link>
        </>
      )}
    </>
  );
}
