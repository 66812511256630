import React, {
  useCallback, useContext, useEffect
} from 'react';
import Region from './Region';

import './Bracket.css';
import { rawResultsToGamePicks, regionPaths } from './shared/helpers';
import Finals from './Finals';
import { GamePick } from './shared/sharedTypes';
import { TeamsContext } from './TeamsContext';
import { Loading } from './Loading';

type BracketProps = {
  rawPicks: number[]; // array of 63 numbers to indicate picks
  // if undefined, bracket should not be edited (and so no games should be set to 0)
  onUpdate: (updatedPicks: number[]) => void;
  rawResults?: number[]; // if undefined, don't add colors
  children?: React.ReactNode;
  viewState?: 'region1' | 'region2' | 'region3' | 'region4' | 'finalFour' | 'entireBracket'
};

export default function Bracket(props: BracketProps) {
  const {
    rawPicks, onUpdate, rawResults, viewState, children
  } = props;
  const teamsInfo = useContext(TeamsContext);

  // picks and results could probably be converted to arrays of objects
  // so game index is in callback

  const picks = rawPicks.map((rawPick, index) => ({
    pickIndex: rawPick,
    gameIndex: index
  }));

  while (picks.length < 63) {
    picks.push({
      pickIndex: -1,
      gameIndex: picks.length
    });
  }

  let results: GamePick[] | undefined;

  if (rawResults) {
    results = rawResultsToGamePicks(rawResults);
  }

  const onRegionUpdate = useCallback((gameIndex: number, pickIndex: number) => {
    const updatedPicks = [...rawPicks];

    while (updatedPicks.length < 63) {
      // TODO: remove all of these eventually
      updatedPicks.push(-1);
    }

    const existingPick = updatedPicks[gameIndex];
    updatedPicks[gameIndex] = pickIndex;

    // also walk the path back because this team should have won all prior games
    // and make sure old team isn't marked as winner after this
    const path = regionPaths[pickIndex] ?? regionPaths[existingPick]; // necessary if new pickIndex is -1
    for (let i = 0; i < path.length; i += 1) {
      const currentGameIndex = path[i];
      if (currentGameIndex === gameIndex) {
        // don't change this game as it was the one that was jut set
      } else if (currentGameIndex > gameIndex) {
        if (updatedPicks[currentGameIndex] === existingPick) {
          // reset this since it has the old value in a later game
          updatedPicks[currentGameIndex] = -1;
        }
      } else if (pickIndex !== -1) {
        // this is a prior game, so the new pick needs this spot
        updatedPicks[currentGameIndex] = pickIndex;
      }
    }

    onUpdate(updatedPicks);
  }, [onUpdate, rawPicks]);

  const bracketRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (bracketRef.current) {
      const width = bracketRef.current.scrollWidth;
      const offset = (width - window.innerWidth) / 2;
      window.scrollTo(offset, 0);
    }
  }, []);

  // why was this necessary?
  // const contestant = new Contestant('Howdy Doo', '1', 'secret-1', contestant1Picks);

  // results = picks for results updater or picking before tourney starts?
  // or should it be null and fall back to picks?

  const stateClass = viewState ?? 'entireBracket';

  if (!teamsInfo?.teams) {
    return (
      <Loading />
    );
  }

  return (
    <div className={`bracket-holder ${stateClass}`}>
      <div className={`bracket ${stateClass}`} ref={bracketRef}>
        <Region
          className="nw-region"
          picks={picks.slice(0, 15)}
          results={results}
          onUpdate={onRegionUpdate}
          regionTeams={teamsInfo.teams.slice(0, 16)}
          regionTitle={teamsInfo.regions[0]}
        />
        <Region
          className="sw-region"
          picks={picks.slice(15, 30)}
          results={results}
          onUpdate={onRegionUpdate}
          regionTeams={teamsInfo.teams.slice(16, 32)}
          regionTitle={teamsInfo.regions[1]}
        />
        <Region
          className="ne-region right-side"
          picks={picks.slice(30, 45)}
          results={results}
          onUpdate={onRegionUpdate}
          regionTeams={teamsInfo.teams.slice(32, 48)}
          regionTitle={teamsInfo.regions[2]}
        />
        <Region
          className="se-region right-side"
          picks={picks.slice(45, 60)}
          results={results}
          onUpdate={onRegionUpdate}
          regionTeams={teamsInfo.teams.slice(48, 64)}
          regionTitle={teamsInfo.regions[3]}
        />
        <Finals picks={picks} results={results} onUpdate={onRegionUpdate} />
        { children }
      </div>
    </div>
  );
}

Bracket.defaultProps = {
  children: null,
  rawResults: undefined,
  viewState: 'entireBracket'
};
