import { isAdmin } from './Admin';
import { ServerResponseType, ServerCall, ServerCallPayload } from './shared/responseTypes';

export default async function serverCall<T extends ServerCall>(
  type: T,
  payload?: ServerCallPayload<T>
): Promise<ServerResponseType<T>> {
  let requestInit;

  if (payload) {
    requestInit = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          ...payload,
          admin: isAdmin()
        }
      )
    };
  }

  const year = process.env.REACT_APP_YEAR;

  // eslint-disable-next-line no-console
  console.log('serverCall:', type);

  let response;
  try {
    response = await fetch(
      `${process.env.REACT_APP_SERVER_ADDRESS}/${type}?year=${year}`,
      requestInit
    );
  } catch (e: unknown) {
    console.error('serverCall error:', e);
    return { success: false, error: e instanceof Error ? e.message : 'Unknown error' };
  }

  // I believe the reason why some of this is necssary is explained here:
  // https://stackoverflow.com/questions/68474835/how-to-use-a-switch-statement-to-narrow-down-a-type-using-conditional-types
  const json = await response.json() as unknown as ServerResponseType<T>;

  // eslint-disable-next-line no-console
  console.log('serverResponse:', type);

  return json;
}
