import React, { useEffect, useState } from 'react';
import { Bars } from 'react-loader-spinner';
import { MessageSubmit } from './MessageSubmit';
import { DiscordChannel, postToDiscord } from './discord';

type Message = {
    name: string;
    message: string;
    date: number;
}

export function MessageBoard() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [posting, setPosting] = useState<boolean>(false);

  useEffect(() => {
    // TODO: move to serverCall
    fetch(
      `${process.env.REACT_APP_SERVER_ADDRESS}/getMessages?year=${process.env.REACT_APP_YEAR}`
    ).then((response) => {
      response.json().then((json) => {
        setMessages(json.messages);
      });
    });
    /*
        setMessages([
            { name: "John", message: "Hello, world!" },
            { name: "Jane", message: "Hi, there!" },
        ]);
        */
  }, []);

  const onSubmit = (name: string, message: string) => {
    setPosting(false);
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/addMessage?year=${process.env.REACT_APP_YEAR}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, message, date: Date.now() })
    }).then((response) => {
      response.json().then((json) => {
        setMessages(json.messages);
      });
    });
    postToDiscord(`${name} says:\n\n>>> ${message}`, DiscordChannel.STATUS);
  };

  return (
    <>
      <hr />
      <div className="message-board-header">
        <div />
        <h1>Message Board</h1>
        <button type="button" onClick={() => { setPosting(!posting); }}>{posting ? 'Cancel' : 'Post'}</button>
      </div>
      {
            posting && <MessageSubmit onSubmit={onSubmit} />
            }
      <div className="message-board-messages">
        {messages.length === 0 && <div><Bars color="#00000044" wrapperClass="message-bars" /></div>}
        { [...messages].reverse().map((message) => {
          const dateString = new Date(message.date).toLocaleString();
          return (
            <React.Fragment key={message.date}>
              <div className="divider" />
              <div className="message">
                <h2>{message.name}</h2>
                <div className="message-date">{dateString}</div>
                <div className="message-text">{message.message}</div>
              </div>
            </React.Fragment>
          );
        }) }
      </div>
      <hr />
    </>
  );
}
