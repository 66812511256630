import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import './index.css';
import Scoreboard from './Scoreboard';
import PickBracket from './PickBracket';
import reportWebVitals from './reportWebVitals';
import Authenticate from './Authenticate';
import { Teams } from './Teams';

import './App.css';

import { Contestants } from './Contestants';
import UpdateResults from './UpdateResults';
import ViewBracket from './ViewBracket';
import Home from './Home';
import Admin from './Admin';
import EnterPool from './EnterPool';
import UpdatePaid from './UpdatePaid';
import { ReportIssue } from './ReportIssue';
import { TeamsProvider } from './TeamsContext';
import { DiscordChannel, postToDiscord } from './discord';
import { tourneyId } from './utilities';
import { Track } from './Track';
import { Simulation } from './Simulation';
import { Masters } from './Masters';
import { MastersView } from './MastersView';
import { Champions } from './Champions';
import { Nba } from './Nba';
import { NbaResults } from './NbaResults';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: 'update',
    element: <Authenticate><UpdateResults /></Authenticate>
  },
  {
    path: 'view/:contestantId',
    element: <ViewBracket />
  },
  {
    path: 'scoreboard',
    element: <Scoreboard />
  },
  {
    path: 'admin',
    element: <Admin />
  },
  {
    path: 'enter',
    element: <EnterPool />
  },
  {
    path: 'pick/:secretId',
    element: <PickBracket />
  },
  {
    path: 'updatePaid',
    element: <Authenticate><UpdatePaid /></Authenticate>
  },
  {
    path: 'report',
    element: <ReportIssue />
  },
  {
    path: 'contestants',
    element: <Authenticate><Contestants /></Authenticate>
  },
  {
    path: 'teams',
    element: <Authenticate><Teams /></Authenticate>
  },
  {
    path: 'track',
    element: <Track />
  },
  {
    path: 'simulation',
    element: <Simulation />
  },
  {
    path: 'masters/pick',
    element: <Masters />
  },
  {
    path: 'masters/view',
    element: <MastersView />
  },
  {
    path: 'masters/update',
    element: <Masters />
  },
  {
    path: 'champions',
    element: <Champions />
  },
  {
    path: 'nba',
    element: <Nba />
  },
  {
    path: 'nba/results',
    element: <NbaResults />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TeamsProvider>
      <RouterProvider router={router} />
      <div style={{ border: '1px solid black', width: 'max-content', padding: '1px' }}>
        {tourneyId()}
      </div>
    </TeamsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.addEventListener('unhandledrejection', async (event) => {
  postToDiscord(
    // eslint-disable-next-line max-len
    `Unhandled Rejection (${process.env.REACT_APP_YEAR} - ${process.env.REACT_APP_ENVIRONMENT ?? ''}): ${event.reason}`,
    DiscordChannel.ERRORS
  );
});

window.addEventListener('error', (event) => {
  // eslint-disable-next-line max-len
  postToDiscord(`Error (${process.env.REACT_APP_YEAR} - ${process.env.REACT_APP_ENVIRONMENT ?? ''}): ${event.message} ${event.error.stack}`, DiscordChannel.ERRORS);
});

// // simulate error
// // @ts-expect-error: Description
// const hey = undefined;
// // @ts-expect-error: Description
// const shouldCrash = hey.doesnotExist;
// console.log('shouldCrash', shouldCrash);

// // simulate unhandled rejection
// const promise = new Promise((resolve, reject) => {
//   reject(new Error('test'));
// });
// console.log(await promise);
