import React, { useEffect } from 'react';

import './Nba.css';
import { DiscordChannel, postToDiscord } from './discord';
import { contestants } from './nbaContestants';

type GameAncestor = {
  index: number,
  winner: boolean
} | {
  team: number
}

class NBAGame {
  ancestor1: GameAncestor;

  ancestor2: GameAncestor;

  winner?: number;

  series: boolean;

  games?: number;

  pointValue: number;

  constructor(ancestor1: GameAncestor, ancestor2: GameAncestor, pointValue: number, series = true) {
    this.ancestor1 = ancestor1;
    this.ancestor2 = ancestor2;
    this.series = series;
    this.pointValue = pointValue;
  }

  // turn into actual setter?
  setWinner(winner: number | undefined, games: NBAGame[]) {
    if (winner === this.winner) {
      // don't do anything
      return;
    }
    // or should this happen either way?
    // if (this.winner === undefined) {
    // set descendents winner to undefined
    const descendents = games
      .filter((game) => ('index' in game.ancestor1 && games[game.ancestor1.index]) === this
        || ('index' in game.ancestor2 && games[game.ancestor2.index] === this));

    descendents.forEach((descendent) => {
      if (descendent.winner === this.winner) {
        // we should clear the descendent's winner
        descendent.setWinner(undefined, games);
      }
    });

    // if winner is defined, all ancestors should also have this winner
    if (winner !== undefined && 'index' in this.ancestor1 && 'index' in this.ancestor2) {
      const game1 = games[this.ancestor1.index];
      const game2 = games[this.ancestor2.index];
      if (game1.incomingTeams(games).includes(winner) && this.ancestor1.winner) {
        game1.setWinner(winner, games);
      }
      if (game2.incomingTeams(games).includes(winner) && this.ancestor2.winner) {
        game2.setWinner(winner, games);
      }
    }

    // do this last so ancestor/descendents don't change it
    this.winner = winner;

    // console.log('descendents', descendents);
    // }
  }

  incomingTeams(games: NBAGame[]): number[] {
    const incoming = [];
    if ('team' in this.ancestor1) {
      incoming.push(this.ancestor1.team);
    }

    if ('team' in this.ancestor2) {
      incoming.push(this.ancestor2.team);
    }

    if ('index' in this.ancestor1) {
      const game1 = games[this.ancestor1.index];

      if (this.ancestor1.winner) {
        incoming.push(...game1.outgoingTeams(games));
      } else if (game1.loser(games)) {
        incoming.push(game1.loser(games));
      }
    }
    if ('index' in this.ancestor2) {
      const game2 = games[this.ancestor2.index];

      if (this.ancestor2.winner) {
        incoming.push(...game2.outgoingTeams(games));
      } else if (game2.loser(games)) {
        incoming.push(game2.loser(games));
      }
    }
    return incoming as number[];
  }

  outgoingTeams(games: NBAGame[]): number[] {
    if (this.winner !== undefined) {
      // console.log('winner', this.winner, this.ancestor1, this.ancestor2);
      return [this.winner];
    }
    return this.incomingTeams(games);
  }

  loser(games: NBAGame[]): number | undefined {
    if (this.winner === undefined || this.incomingTeams(games).length !== 2) {
      return undefined;
    }
    const [team1, team2] = this.incomingTeams(games);

    console.log('team1', team1, 'team2', team2, 'winner', this.winner);
    return team1 === this.winner ? team2 : team1;
  }
}

const startingGames = [
  // west
  new NBAGame({ index: 1, winner: true }, { index: 2, winner: true }, 5), // conference finals
  new NBAGame({ index: 3, winner: true }, { index: 4, winner: true }, 3),
  new NBAGame({ index: 5, winner: true }, { index: 6, winner: true }, 3),
  new NBAGame({ team: 0 }, { index: 7, winner: true }, 2),
  new NBAGame({ team: 1 }, { team: 2 }, 2),
  new NBAGame({ team: 3 }, { team: 4 }, 2),
  new NBAGame({ index: 9, winner: true }, { team: 5 }, 2), // game 6
  new NBAGame({ index: 8, winner: true }, { index: 9, winner: false }, 1, false), // game 7
  new NBAGame({ team: 6 }, { team: 7 }, 1, false), // game 8
  new NBAGame({ team: 8 }, { team: 9 }, 1, false),
  // east
  new NBAGame({ index: 1 + 10, winner: true }, { index: 2 + 10, winner: true }, 5), // conference finals
  new NBAGame({ index: 3 + 10, winner: true }, { index: 4 + 10, winner: true }, 3),
  new NBAGame({ index: 5 + 10, winner: true }, { index: 6 + 10, winner: true }, 3),
  new NBAGame({ team: 0 + 10 }, { index: 7 + 10, winner: true }, 2),
  new NBAGame({ team: 1 + 10 }, { team: 2 + 10 }, 2),
  new NBAGame({ team: 3 + 10 }, { team: 4 + 10 }, 2),
  new NBAGame({ index: 9 + 10, winner: true }, { team: 5 + 10 }, 2), // game 6
  new NBAGame({ index: 8 + 10, winner: true }, { index: 9 + 10, winner: false }, 1, false), // game 7
  new NBAGame({ team: 6 + 10 }, { team: 7 + 10 }, 1, false), // game 8
  new NBAGame({ team: 8 + 10 }, { team: 9 + 10 }, 1, false),
  // championship
  new NBAGame({ index: 0, winner: true }, { index: 10, winner: true }, 8)
];

/*
// for testing, load up the games
const jeffPicks = {
  name: 'Jeff',
  picks: [
    {
      winner: 5,
      games: 6
    },
    {
      winner: 0,
      games: 6
    },
    {
      winner: 5,
      games: 6
    },
    {
      winner: 0,
      games: 7
    },
    {
      winner: 2,
      games: 6
    },
    {
      winner: 3,
      games: 6
    },
    {
      winner: 5,
      games: 5
    },
    {
      winner: 7
    },
    {
      winner: 7
    },
    {
      winner: 8
    },
    {
      winner: 10,
      games: 5
    },
    {
      winner: 10,
      games: 5
    },
    {
      winner: 15,
      games: 7
    },
    {
      winner: 10,
      games: 6
    },
    {
      winner: 11,
      games: 7
    },
    {
      winner: 14,
      games: 7
    },
    {
      winner: 15,
      games: 7
    },
    {
      winner: 19
    },
    {
      winner: 17
    },
    {
      winner: 18
    },
    {
      winner: 5,
      games: 6
    }
  ]
};

jeffPicks.picks.forEach((pick, index) => {
  startingGames[index].setWinner(pick.winner, startingGames);
  if (pick.games) {
    startingGames[index].games = pick.games;
  }
});
*/

const teams = [
  // first west
  'Thunder (1)',
  'Clippers (4)',
  'Mavs (5)',
  'Wolves (3)',
  'Suns (6)',
  'Nuggets (2)',
  'Kings (9)',
  'Warriors (10)',
  'Pelicans (7)',
  'Lakers (8)',
  // now east
  'Celtics (1)',
  'Cavs (4)',
  'Magic (5)',
  'Bucks (3)',
  'Pacers (6)',
  'Knicks (2)',
  'Bulls (9)',
  'Hawks (10)',
  '76ers (7)',
  'Heat (8)'
];

const contestant1 = contestants[0];

export type ResultsType = {
  winner?: number,
  games?: number
}[];

export function NbaPicks(props:
  { contestantPicks:
    {
      picks: ResultsType,
      name: string },
      picking: boolean, allowSubmit: boolean, onSubmit: ((results: ResultsType) => void) | undefined }) {
  const {
    contestantPicks, picking, allowSubmit, onSubmit
  } = props;
  const [currentGames, setCurrentGames] = React.useState(contestantPicks.picks
    .map((pick, index) => {
      const newGame = new NBAGame(
        startingGames[index].ancestor1,
        startingGames[index].ancestor2,
        startingGames[index].pointValue,
        startingGames[index].series
      );
      newGame.games = pick.games;
      newGame.setWinner(pick.winner, startingGames);
      console.log(newGame);
      return newGame;
    }));
  const [name, setName] = React.useState<string | undefined>(undefined);
  const [submitted, setSubmitted] = React.useState<boolean>(false);

  const [points, setPoints] = React.useState<number>(0);

  const [results, setResults] = React.useState<ResultsType | undefined>(undefined);

  useEffect(() => {
    async function getResults() {
      const result = await fetch(
        `${process.env.REACT_APP_SERVER_ADDRESS}/getNbaResults?year=${process.env.REACT_APP_YEAR}`
      );
      const json = await result.json();
      setResults(json.results);
    }
    getResults();
  }, []);

  useEffect(() => {
    if (!results) {
      return;
    }
    let updatedTotal = 0;
    contestantPicks.picks.forEach((pick, index) => {
      const gameResult = results[index];
      if ('winner' in gameResult && gameResult.winner === pick.winner) {
        if (gameResult.games && gameResult.games === pick.games) {
          updatedTotal += 2 * startingGames[index].pointValue;
        } else {
          updatedTotal += startingGames[index].pointValue;
        }
      } else if (gameResult.games && gameResult.games === pick.games) {
        // give 1 extra point for correct series length
        updatedTotal += 1;
      }
    });
    setPoints(updatedTotal);
    // setCurrentGames(startingGames); //
  }, [contestantPicks.picks, results]);

  // console.log('currentGames', currentGames);
  console.log('points', points);

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const [gameIndex, teamIndex] = event.target.value.split(',').map((str) => parseInt(str, 10));
    const newGames = [...currentGames];
    newGames[gameIndex].setWinner(teamIndex, currentGames);

    setCurrentGames(newGames);

    console.log('newGames', newGames);

    console.log('newGame winners', newGames.map((game) => ({
      winner: game.winner,
      games: game.games
    })));
  };

  const onGamesChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const [gameIndex, games] = event.target.value.split(',').map((str) => parseInt(str, 10));
    const newGames = [...currentGames];
    newGames[gameIndex].games = games;

    setCurrentGames(newGames);

    console.log('newGames', newGames);

    console.log('newGame winners', newGames.map((game) => game.winner));
  };

  const submit = () => {
    const picks = currentGames.map((game) => ({
      winner: game.winner,
      games: game.games
    }));

    const dataToSubmit = {
      name,
      picks
    };
    if (onSubmit) {
      onSubmit(picks);
      return;
    }
    console.log('submitting', currentGames);
    // const picks = currentGames.map((game) => game.winner);

    console.log('dataToSubmit', dataToSubmit);
    postToDiscord(JSON.stringify(dataToSubmit, null, 2), DiscordChannel.BOT_TESTING);
    setSubmitted(true);
  };

  if (!results) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  if (submitted) {
    return (
      <div>
        <h1>Submitted!</h1>
        <p>
          Thank you for submitting your picks!
        </p>
      </div>
    );
  }

  return (
    <>
      {picking && (
      <>
        <h1>NBA Playoffs</h1>
        <p>Let us go</p>
        <div>
          <b>
            Instructions:
          </b>
          <br />
          Select the winner of each game/series.
          <br />
          The first 3 are play-in games.
          (And do not forget the loser of the 7/8 play-in plays the winner of the 9/10 play-in)
          <br />
          For each series, pick the number of games you think the series will last.
        </div>
        <br />
        <button
          type="button"
          onClick={submit}
          disabled={!allowSubmit && (currentGames.some((game) => game.winner === undefined
          || (game.series && game.games === undefined) || !name))}
        >
          Submit
        </button>
        <br />
        <br />
        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
        <label htmlFor="name">
          {'Name: '}
        </label>
        <input id="name" type="text" value={name} onChange={(event) => setName(event.target.value)} />
      </>
      )}
      <br />
      <h2>
        West
      </h2>
      <div
        style={
          {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'repeat(6, 150px)',
            gridTemplateRows: 'repeat(10, 25px)'
          }
        }
      >
        {currentGames.slice(0, 10).map((game, index) => (
          <div key={`game-${index + 1}`} className={`game-${index}`}>
            <div
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <select
                className={`game-${index} game`}
                onChange={onSelectChange}
                value={`${index},${game.winner}`}
                style={{
                  // eslint-disable-next-line no-nested-ternary
                  backgroundColor: results[index].winner === undefined
                    ? 'white' : game.winner === results[index].winner ? 'yellow' : '#C4A484'
                }}
              >
                <option value={`${index}`}>
                  -
                </option>
                {game.incomingTeams(currentGames).map((team: number) => (
                  <option key={team} value={`${index},${team}`}>
                    {teams[team]}
                  </option>
                ))}
              </select>
              {
                game.series
              && (
              <select
                value={`${index},${game.games}`}
                onChange={onGamesChange}
                style={{
                  // eslint-disable-next-line no-nested-ternary
                  backgroundColor: results[index].games === undefined
                    ? 'white' : game.games && game.games === results[index].games ? 'yellow' : '#C4A484'
                }}
              >
                <option value={`${index}`}>-</option>
                <option value={`${index},4`}>4</option>
                <option value={`${index},5`}>5</option>
                <option value={`${index},6`}>6</option>
                <option value={`${index},7`}>7</option>
              </select>
              )
          }
              {
                results[index].winner !== undefined && (
                  <div
                    style={{
                      border: '1px solid green',
                      borderRadius: '11px',
                      width: '22px',
                      height: '22px',
                      alignContent: 'center',
                      textAlign: 'center',
                      color: 'white',
                      backgroundColor: 'black',
                      display: game.winner === results[index].winner
                      || (game.games && game.games === results[index].games) ? 'unset' : 'none'
                    }}
                  >
                    {/* game.winner === results[index].winner ? '✅' : '❌' */}
                    {/* game.games && game.games === results[index].games ? '✅' : '❌' */}
                    {(() => {
                      if (game.winner !== results[index].winner) {
                        return '';
                      }
                      // if winner and games correct, double the points
                      if (game.games && game.games === results[index].games) {
                        return `+${2 * game.pointValue}`;
                      }
                      return `+${game.pointValue}`;
                    })()}
                  </div>
                )
              }
            </div>
          </div>
        ))}
        {teams.slice(0, 10).map((teamName, index) => (
          <div key={`team-${index + 1}`} className={`team-${index}`}>
            {teamName}
          </div>
        ))}
      </div>
      <h2>
        East
      </h2>
      <div
        style={
          {
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'repeat(6, 150px)',
            gridTemplateRows: 'repeat(10, 25px)'
          }
        }
      >
        {currentGames.slice(10, 20).map((game, index) => (
          <div key={`game-${index + 1}`} className={`game-${index}`}>
            <div
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <select
                className={`game-${index} game`}
                onChange={onSelectChange}
                value={`${index + 10},${game.winner}`}
                style={{
                  // eslint-disable-next-line no-nested-ternary
                  backgroundColor: !results[index + 10].winner
                    ? 'white' : game.winner === results[index + 10].winner ? 'yellow' : '#C4A484'
                }}
              >
                <option value={`${index}`}>
                  -
                </option>
                {game.incomingTeams(currentGames).map((team: number) => (
                  <option key={team} value={`${index + 10},${team}`}>
                    {teams[team]}
                  </option>
                ))}
              </select>
              {
                game.series
              && (
                <select
                  value={`${index + 10},${game.games}`}
                  onChange={onGamesChange}
                  style={{
                  // eslint-disable-next-line no-nested-ternary
                    backgroundColor: results[index + 10].games === undefined
                      ? 'white' : game.games
                      && game.games === results[index + 10].games ? 'yellow' : '#C4A484'
                  }}
                >
                  <option value={`${index + 10}`}>-</option>
                  <option value={`${index + 10},4`}>4</option>
                  <option value={`${index + 10},5`}>5</option>
                  <option value={`${index + 10},6`}>6</option>
                  <option value={`${index + 10},7`}>7</option>
                </select>
              )
            }
              {
                results[index + 10].winner !== undefined && (
                  <div
                    style={{
                      border: '1px solid green',
                      borderRadius: '11px',
                      width: '22px',
                      height: '22px',
                      alignContent: 'center',
                      textAlign: 'center',
                      color: 'white',
                      backgroundColor: 'black',
                      display: game.winner === results[index + 10].winner
                      || (game.games && game.games === results[index + 10].games) ? 'unset' : 'none'
                    }}
                  >
                    {/* game.winner === results[index].winner ? '✅' : '❌' */}
                    {/* game.games && game.games === results[index].games ? '✅' : '❌' */}
                    {(() => {
                      if (game.winner !== results[index + 10].winner) {
                        return '';
                      }
                      // if winner and games correct, double the points
                      if (game.games && game.games === results[index + 10].games) {
                        return `+${2 * game.pointValue}`;
                      }
                      return `+${game.pointValue}`;
                    })()}
                  </div>
                )
              }
            </div>
          </div>
        ))}
        {teams.slice(10).map((teamName, index) => (
          <div key={`team-${index + 1}`} className={`team-${index}`}>
            {teamName}
          </div>
        ))}
      </div>
      <div key="game-championship" className="game-championship">
        <h2>
          Championship
        </h2>
        <select
          className="game-championship"
          onChange={onSelectChange}
          value={`20,${currentGames[20].winner}`}
        >
          <option value="20">
            -
          </option>
          {currentGames[20].incomingTeams(currentGames).map((team: number) => (
            <option key={team} value={`20,${team}`}>
              {teams[team]}
            </option>
          ))}
        </select>
        <select value={`${20},${currentGames[20].games}`} onChange={onGamesChange}>
          <option value="20">-</option>
          <option value={`${20},4`}>4</option>
          <option value={`${20},5`}>5</option>
          <option value={`${20},6`}>6</option>
          <option value={`${20},7`}>7</option>
        </select>
      </div>
      <br />
      <br />
      {picking && (
        <div>
          Scoring:
          <br />
          Play-in tourney: 1 pt per game (no series length, obvs)
          <br />
          Round 1: 2 pt per series
          <br />
          Conference semis: 3 pt per series
          <br />
          Conference finals: 5 pt per series
          <br />
          Finals: 8 pts
          <br />
          <br />
          Correct series length gives 1 extra point
          <br />
          Correct series length + correct pick doubles amount for correct pick
          <br />
          (do not get both the extra point too)
        </div>
      )}
    </>
  );
}

export function Nba() {
  const [results, setResults] = React.useState<ResultsType | undefined>(undefined);
  useEffect(() => {
    async function getResults() {
      const result = await fetch(
        `${process.env.REACT_APP_SERVER_ADDRESS}/getNbaResults?year=${process.env.REACT_APP_YEAR}`
      );
      const json = await result.json();
      setResults(json.results);
    }
    getResults();
  }, []);

  if (!results) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  const pointedContestants = contestants.map((contestant) => {
    const points = contestant.picks.reduce((acc, pick, index) => {
      if ('winner' in results[index] && results[index].winner === pick.winner) {
        if (results[index].games && results[index].games === pick.games) {
          return acc + 2 * startingGames[index].pointValue;
        }
        return acc + startingGames[index].pointValue;
      }
      if (results[index].games && results[index].games === pick.games) {
        return acc + 1;
      }
      return acc;
    }, 0);
    return {
      contestant,
      points
    };
  });
  pointedContestants.sort((a, b) => b.points - a.points);
  return (
    <div>
      <h1>NBA Playoffs</h1>
      {pointedContestants.map((pointedContestant) => (
        <>
          <h1>
            {pointedContestant.contestant.name}
            :
            {' '}
            {pointedContestant.points}
          </h1>
          <NbaPicks
            key={pointedContestant.contestant.name}
            contestantPicks={{
              picks: pointedContestant.contestant.picks,
              name: pointedContestant.contestant.name
            }}
            picking={false}
            allowSubmit={false}
            onSubmit={undefined}
          />
          <hr />
        </>
      ))}
    </div>
  );
}
