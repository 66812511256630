import React, { useCallback, useEffect } from 'react';
import { NbaPicks, ResultsType } from './Nba';

export function NbaResults() {
  // const resultPicks = results.map((result) => result.games);
  const [results, setResults] = React.useState<ResultsType | undefined>(undefined);
  useEffect(() => {
    async function getResults() {
      const result = await fetch(
        `${process.env.REACT_APP_SERVER_ADDRESS}/getNbaResults?year=${process.env.REACT_APP_YEAR}`
      );
      const json = await result.json();

      if (json.results) {
        setResults(json.results);
      } else {
        console.log('no results found');
        const freshResults = Array(21).fill({ winner: undefined, games: undefined });
        setResults(freshResults);
        // eslint-disable-next-line max-len
        const updateResult = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/updateNbaResults?year=${process.env.REACT_APP_YEAR}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ results: freshResults })
        });
        console.log('updateResult', updateResult);
      }
    }
    getResults();
  }, []);

  console.log('results', results);

  const onSubmit = useCallback(async (updatedResults: ResultsType) => {
    // eslint-disable-next-line max-len
    const updateResult = await fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/updateNbaResults?year=${process.env.REACT_APP_YEAR}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ results: updatedResults })
    });
  }, []);

  if (!results) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }
  return (
    <>
      <h1>NBA Results</h1>
      <NbaPicks
        contestantPicks={{
          picks: results,
          name: 'Results'
        }}
        picking
        allowSubmit
        onSubmit={onSubmit}
      />
    </>
  );
}
