import React, { useEffect } from 'react';
import loading from './images/loading.png';
import conor from './images/conor.png';

export function Loading(props: { timeoutInMS?: number }) {
  const { timeoutInMS } = props;
  const [timedOut, setTimedOut] = React.useState(false);

  useEffect(() => {
    if (timeoutInMS !== 0) {
      const timeout = setTimeout(() => {
        setTimedOut(true);
      }, timeoutInMS);

      return () => {
        clearTimeout(timeout);
      };
    }

    return undefined;
  }, [timeoutInMS]);

  return (
    <div className="loading">
      <img src={loading} className="rotate" alt="loading" />
      <img src={conor} className="orbit first" alt="loading" />
      <img src={conor} className="orbit second" alt="loading" />
      <img src={conor} className="orbit third" alt="loading" />
      {timedOut && <h2 className="timed-out-message">Something has gone wrong...please reload the page.</h2> }
    </div>
  );
}

Loading.defaultProps = {
  timeoutInMS: 10000
};
