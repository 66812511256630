import { useState, useEffect } from 'react';
import serverCall from '../serverCall';

export function useResultsObject() {
  const [results, setResults] = useState<{
    games: number[], started: boolean, ready: boolean } | undefined>(undefined);

  function fetchResults() {
    serverCall('getResults').then((response) => {
      if (response.success) {
        setResults(response);
      }
    });
  }

  // immediately get current results after load
  useEffect(() => {
    fetchResults();
  }, []);

  // also set up websocket connection
  useEffect(() => {
    let exit = false;
    let alive = true;

    function createSocket() {
      // eslint-disable-next-line no-console
      console.log('creating socket');
      const ws: WebSocket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL ?? '');

      ws.addEventListener('message', (event) => {
        // mark alive for any message received
        alive = true;
        if (event.data === 'update') {
          fetchResults();
        }
      });

      return ws;
    }

    let ws: WebSocket = createSocket();
    const socketReconnectionTimeout: NodeJS.Timeout = setInterval(() => {
      if (exit) {
        ws.close();
        return;
      }

      if (!alive) {
        ws.close();
        ws = createSocket();
      }

      if (ws.readyState === WebSocket.OPEN) {
        // only attempt the ping if socket is open
        alive = false;
        ws.send('ping');
      } else if (ws.readyState === WebSocket.CLOSED) {
        // just reconnect since connection is closed
        console.warn('socket closed');
        ws.close();
        ws = createSocket();
      } // else allow to connect or finish closing
    }, 5 * 60 * 1000); // check that we're connected every five minutes

    return () => {
      exit = true;
      clearInterval(socketReconnectionTimeout);
      ws.close();
    };
  }, [setResults]);

  return results;
}
export default function useResults() {
  const results = useResultsObject();
  return results?.games;
}
