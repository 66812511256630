export const results = [
  { // game 0
    winner: undefined,
    games: undefined
  },
  {
    winner: undefined,
    games: undefined
  },
  {
    winner: undefined,
    games: undefined
  },
  {
    winner: undefined,
    games: undefined
  },
  {
    winner: undefined,
    games: undefined
  },
  { // game 5
    winner: undefined,
    games: undefined
  },
  {
    winner: undefined,
    games: undefined
  },
  {
    winner: undefined
  },
  { // game 8
    winner: 6
  },
  { // game 9
    winner: 9
  },
  { // game 10 (east)
    winner: undefined,
    games: undefined
  },
  {
    winner: undefined,
    games: undefined
  },
  {
    winner: undefined,
    games: undefined
  },
  {
    winner: undefined,
    games: undefined
  },
  {
    winner: undefined,
    games: undefined
  },
  { // game 15
    winner: undefined,
    games: undefined
  },
  {
    winner: undefined,
    games: undefined
  },
  {
    winner: undefined
  },
  {
    winner: 16
  },
  {
    winner: 18
  },
  { // game 20
    winner: undefined,
    games: undefined
  }
];

export const contestants = [
  {
    name: 'Jeff',
    picks: [
      {
        winner: 5,
        games: 6
      },
      {
        winner: 0,
        games: 6
      },
      {
        winner: 5,
        games: 6
      },
      {
        winner: 0,
        games: 7
      },
      {
        winner: 2,
        games: 6
      },
      {
        winner: 3,
        games: 6
      },
      {
        winner: 5,
        games: 5
      },
      {
        winner: 7
      },
      {
        winner: 7
      },
      {
        winner: 8
      },
      {
        winner: 10,
        games: 5
      },
      {
        winner: 10,
        games: 5
      },
      {
        winner: 15,
        games: 7
      },
      {
        winner: 10,
        games: 6
      },
      {
        winner: 11,
        games: 7
      },
      {
        winner: 14,
        games: 7
      },
      {
        winner: 15,
        games: 7
      },
      {
        winner: 19
      },
      {
        winner: 17
      },
      {
        winner: 18
      },
      {
        winner: 5,
        games: 6
      }
    ]
  },
  {
    name: 'Thomas',
    picks: [
      {
        winner: 3,
        games: 6
      },
      {
        winner: 0,
        games: 6
      },
      {
        winner: 3,
        games: 7
      },
      {
        winner: 0,
        games: 5
      },
      {
        winner: 2,
        games: 5
      },
      {
        winner: 3,
        games: 7
      },
      {
        winner: 5,
        games: 5
      },
      {
        winner: 8
      },
      {
        winner: 6
      },
      {
        winner: 9
      },
      {
        winner: 10,
        games: 5
      },
      {
        winner: 10,
        games: 5
      },
      {
        winner: 13,
        games: 6
      },
      {
        winner: 10,
        games: 4
      },
      {
        winner: 11,
        games: 5
      },
      {
        winner: 13,
        games: 4
      },
      {
        winner: 15,
        games: 6
      },
      {
        winner: 18
      },
      {
        winner: 16
      },
      {
        winner: 19
      },
      {
        winner: 3,
        games: 6
      }
    ]
  },
  {
    name: 'Kelly',
    picks: [
      {
        winner: 3,
        games: 7
      },
      {
        winner: 0,
        games: 5
      },
      {
        winner: 3,
        games: 7
      },
      {
        winner: 0,
        games: 4
      },
      {
        winner: 1,
        games: 5
      },
      {
        winner: 3,
        games: 7
      },
      {
        winner: 5,
        games: 6
      },
      {
        winner: 6
      },
      {
        winner: 6
      },
      {
        winner: 9
      },
      {
        winner: 10,
        games: 4
      },
      {
        winner: 10,
        games: 4
      },
      {
        winner: 15,
        games: 6
      },
      {
        winner: 10,
        games: 4
      },
      {
        winner: 11,
        games: 5
      },
      {
        winner: 14,
        games: 7
      },
      {
        winner: 15,
        games: 4
      },
      {
        winner: 17
      },
      {
        winner: 17
      },
      {
        winner: 19
      },
      {
        winner: 3,
        games: 7
      }
    ]
  },
  {
    name: 'Joel',
    picks: [
      {
        winner: 5,
        games: 6
      },
      {
        winner: 0,
        games: 5
      },
      {
        winner: 5,
        games: 6
      },
      {
        winner: 0,
        games: 6
      },
      {
        winner: 1,
        games: 6
      },
      {
        winner: 3,
        games: 6
      },
      {
        winner: 5,
        games: 5
      },
      {
        winner: 7
      },
      {
        winner: 7
      },
      {
        winner: 9
      },
      {
        winner: 10,
        games: 5
      },
      {
        winner: 10,
        games: 5
      },
      {
        winner: 15,
        games: 6
      },
      {
        winner: 10,
        games: 5
      },
      {
        winner: 11,
        games: 5
      },
      {
        winner: 14,
        games: 7
      },
      {
        winner: 15,
        games: 6
      },
      {
        winner: 16
      },
      {
        winner: 16
      },
      {
        winner: 19
      },
      {
        winner: 10,
        games: 6
      }
    ]
  },
  {
    name: 'Lydia',
    picks: [
      {
        winner: 3,
        games: 5
      },
      {
        winner: 0,
        games: 5
      },
      {
        winner: 3,
        games: 6
      },
      {
        winner: 0,
        games: 5
      },
      {
        winner: 2,
        games: 6
      },
      {
        winner: 3,
        games: 6
      },
      {
        winner: 5,
        games: 5
      },
      {
        winner: 6
      },
      {
        winner: 6
      },
      {
        winner: 8
      },
      {
        winner: 10,
        games: 5
      },
      {
        winner: 10,
        games: 4
      },
      {
        winner: 13,
        games: 6
      },
      {
        winner: 10,
        games: 5
      },
      {
        winner: 12,
        games: 6
      },
      {
        winner: 13,
        games: 5
      },
      {
        winner: 15,
        games: 5
      },
      {
        winner: 17
      },
      {
        winner: 17
      },
      {
        winner: 19
      },
      {
        winner: 3,
        games: 6
      }
    ]
  }
];
