import React from 'react';
import { Link } from 'react-router-dom';
import { useContestants } from './Scoreboard';

export function Contestants() {
  const contestants = useContestants();

  const totalContestants = contestants?.length ?? 0;
  const completedContestants = contestants?.filter((contestant) => contestant.bracketComplete).length ?? 0;

  return (
    <div>
      <h1>Contestants</h1>
      <ul>
        {contestants?.map((contestant) => (
          <li key={contestant.id}>
            {contestant.name}
            {' '}
            -
            {' '}
            <Link to={`/pick/${contestant.secretId}`}>Edit Picks</Link>
            {' '}
            {contestant.bracketComplete ? '✅' : '❌'}
          </li>
        ))}
      </ul>
      <p>
        {completedContestants}
        {' '}
        of
        {' '}
        {totalContestants}
        {' '}
        contestants have completed their brackets
      </p>
    </div>
  );
}
