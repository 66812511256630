import React, { createContext, useEffect, useState } from 'react';
import serverCall from './serverCall';

type TourneyInfo = {
  teams: string[],
  regions: string[],
  title: string,
  announcement?: {
    heading: string;
    message: string;
    link: {
      href: string;
      text: string;
    }
  };
};

const TourneyDefaults: TourneyInfo = {
  teams: [],
  regions: ['', '', '', ''],
  title: 'The 23rd Annual 302 Tournament' // 23rd in 2024, etc
};

export const TeamsContext = createContext<TourneyInfo | undefined>(undefined);

export function TeamsProvider(props: { children: React.ReactNode }) {
  const { children } = props;
  const [teams, setTeams] = useState<TourneyInfo | undefined
  >(undefined);
  useEffect(() => {
    serverCall('getTeams').then((response) => {
      if (response.success) {
        setTeams({
          teams: response.teams ?? TourneyDefaults.teams,
          regions: (response.regions ?? TourneyDefaults.regions),
          title: response.title ?? TourneyDefaults.title,
          announcement: response.announcement
        });
      }
    });
  }, []);

  return (
    <TeamsContext.Provider value={teams}>
      {children}
    </TeamsContext.Provider>
  );
}
