import React, { ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Loading } from './Loading';
import { TeamsContext } from './TeamsContext';
import { useContestants } from './Scoreboard';
import Contestant from './shared/Contestant';

export function Track() {
  const tourneyInfo = useContext(TeamsContext);
  const contestants = useContestants();

  const [matchingContestants, setMatchingContestants] = React.useState<ReactElement[]>([]);

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!contestants) {
      setMatchingContestants([]);
      return;
    }
    const teamIndex = parseInt(event.target.value, 10);

    if (Number.isNaN(teamIndex)) {
      // likely this means first 'select a team' option was selected
      setMatchingContestants([]);
      return;
    }

    const contestantsWithPick = contestants.filter(
      (contestant) => contestant.roundForTeam(teamIndex) !== 0
    );
    // sort by round number but seem inefficient to get roundForTeam twice?
    contestantsWithPick?.sort(
      (a, b) => b.roundForTeam(teamIndex) - a.roundForTeam(teamIndex)
    );
    setMatchingContestants(contestantsWithPick.map(
      (contestant) => (
        <div
          key={contestant.id}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Link to={`/view/${contestant.id}`}>{contestant.name}</Link>
          <div style={{ alignSelf: 'end' }}>
            {Contestant.roundNumberToRoundName(contestant.roundForTeam(teamIndex))}
          </div>
        </div>
      )
    ));
  };

  if (!tourneyInfo || !contestants) {
    return <Loading />;
  }

  const { teams } = tourneyInfo;

  return (
    <div
      className="track"
      style={
        {
          display: 'flex',
          flexDirection: 'column',
          width: '400px',
          marginLeft: '4em',
          marginBottom: '4em',
          marginTop: '1em',
          gap: '8px'
        }
    }
    >
      <h2>Look Up Contestants By Team</h2>
      <select onChange={onChange}>
        <option id="-1">Select a team</option>
        {teams.map((team, index) => (
          <option key={team} data-index={`${index}`} value={index}>{team}</option>
        ))}
      </select>
      {matchingContestants}
    </div>
  );
}
