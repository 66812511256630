import React, { useCallback } from 'react';
import { GamePick } from './shared/sharedTypes';
import Games from './Games';

// const teams = llamaTeams;

// team # is based on position in bracket, not seed
// and is 0-based
// e.g.
// Team 0 is 1 seed in region 1
// Team 1 is 16 seed in region 1
// etc

export default function Region(props: {
  className: string;
  picks: GamePick[]; // array of length 15
  results?: GamePick[]; // also array of length 15,
  onUpdate: (gameIndex: number, teamIndex: number) => void;
  regionTeams: string[];
  regionTitle: string;
}) {
  const {
    className, results, picks, onUpdate, regionTeams, regionTitle
  } = props;

  // duplicate in Finals.tsx, should be consolidated
  const onGameChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const gameIndexString = event.target.value.split('.')[0];
      const teamIndexString = event.target.value.split('.')[1];

      const gameIndex = parseInt(gameIndexString, 10);
      const teamIndex = parseInt(teamIndexString, 10);

      onUpdate(gameIndex, teamIndex);
    },
    [onUpdate]
  );

  return (
    <div className={`${className} region`}>
      {regionTeams.map((team, index) => {
        const evenIndex = index % 2 === 0;
        return <div className={`team team-${evenIndex ? 'even' : 'odd'}`} key={`team-${team}`}>{team}</div>;
      })}
      <Games
        picks={picks}
        games={picks}
        onGameChange={onGameChange}
        results={results}
      />
      {[1, 2, 3, 4, 5, 6, 7].map(
        (identifier) => (
          <div
            className={`divider vertical d-${identifier}`}
            key={`divider-v-${identifier}`}
          />
        )
      )}
      {[1, 2, 3, 4, 5, 6, 7].map(
        (identifier) => (
          <div
            className={`divider horizontal d-${identifier}`}
            key={`divider-h-${identifier}`}
          />
        )
      )}
      <div className="title">
        {regionTitle}
      </div>
    </div>
  );
}

Region.defaultProps = {
  results: undefined
};
