import React, { useEffect } from 'react';
import { golfersInWorldRanking, useCt } from './Masters';

const contestants: {
  picks: number[];
  name: string;
}[] = [];

/*
contestants.push(
  { picks: [6, 15, 30, 45, 65, 72], name: 'Cup Bottleby' }
);
*/

contestants.push(
  { picks: [2, 18, 29, 47, 60, 77], name: 'Jeff' }
);

contestants.push(
  { picks: [2, 15, 27, 51, 67, 88], name: 'Thomas ' }
);

contestants.push(
  { picks: [5, 18, 36, 44, 56, 74], name: 'Kelly' }
);

contestants.push(
  { picks: [2, 18, 28, 52, 56, 77], name: 'Tom Boerner' }
);

contestants.push(
  { picks: [2, 14, 28, 41, 57, 83], name: 'Karen Boerner' }
);

function scoresForGolfer(rank: number, liveResults: string): {
  name: string;
  round1: number;
  round2: number;
  round3: number;
  round4: number;
  total: number;
  through: string;
  roundScore: string;
} {
  const golferName = golfersInWorldRanking.find((golfer) => golfer.rank === rank)?.name;
  if (!golferName) {
    console.error('no golfer name for rank', rank);
    return {
      name: 'Unknown Golfer',
      round1: 8,
      round2: 8,
      round3: 8,
      round4: 8,
      total: 32,
      through: 'F',
      roundScore: '80'
    };
  }
  const golferScoreLine = liveResults.split('\n').find((line) => line.includes(golferName));
  if (!golferScoreLine) {
    console.error('no golfer score line for', golferName);
    return {
      name: golferName,
      round1: 8,
      round2: 8,
      round3: 8,
      round4: 8,
      total: 32,
      through: 'F',
      roundScore: '80'
    };
  }
  const stringScores = golferScoreLine.split('|');

  console.log('stringScores', JSON.stringify(stringScores, null, 2));

  const cut = (stringScores[0] === 'CUT' || stringScores[0] === 'W/D');

  const fallbackScore = cut ? 80 : NaN; // cut get an 80 which is 8 over par

  // is a string but can be F or a number
  const through = stringScores[0] === 'CUT' ? 'CUT' : stringScores[4];
  const roundScore = stringScores[0] === 'CUT' ? 'CUT' : stringScores[5];

  const roundScores = {
    round1: stringScores[6] !== '' ? parseInt(stringScores[6], 10) : fallbackScore,
    round2: stringScores[7] !== '' ? parseInt(stringScores[7], 10) : fallbackScore,
    round3: (stringScores[8] !== '' && stringScores[0] !== 'CUT')
      ? parseInt(stringScores[8], 10) : fallbackScore,
    round4: (stringScores[9] !== '' && stringScores[0] !== 'CUT')
      ? parseInt(stringScores[9], 10) : fallbackScore,
    through,
    roundScore
  };

  console.log('roundScores', roundScores);

  /*
  // change NaN to 0 for total
  const total = (Number.isNaN(roundScores.round1) ? 0 : roundScores.round1)
  + (Number.isNaN(roundScores.round2) ? 0 : roundScores.round2)
  + (Number.isNaN(roundScores.round3) ? 0 : roundScores.round3)
  + (Number.isNaN(roundScores.round4) ? 0 : roundScores.round4);
  */
  let total = parseInt(stringScores[3].replace('E', '0'), 10);

  if (stringScores[0] === 'CUT') {
    total += 16;
  }

  return {
    name: golferName,
    ...roundScores,
    total
  };
}

function scoresForContestant(contestant: { picks: number[]; name: string }, liveResults: string) {
  // TODO: with actual game, should never need fallback
  const scores = contestant.picks.map((golferRank, tierIndex) => ({
    tier: tierIndex + 1,
    ...scoresForGolfer(golferRank, liveResults)
  }));
  scores.sort((a, b) => a.total - b.total);
  const useScores = scores.slice(0, useCt);
  const total = useScores.reduce((a, b) => a + b.total, 0);
  return {
    name: contestant.name,
    total,
    scores
  };
}

export function MastersView() {
  const [liveResults, setLiveResults] = React.useState<string | undefined>(undefined);
  const [lastUpdated, setLastUpdated] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/getMastersResults?year=${process.env.REACT_APP_YEAR}`)
      .then((response) => {
        response.json().then((json) => {
          console.log('masters results', json);
          setLiveResults(json.results);
          setLastUpdated(json.lastUpdated);
        });
      });
  }, []);

  if (!liveResults) {
    return null;
  }

  const scores = contestants.map((contestant) => scoresForContestant(contestant, liveResults));
  scores.sort((a, b) => a.total - b.total);

  const date = new Date(parseInt(lastUpdated ?? '0', 10));

  return (
    <div style={{ margin: '1em' }}>
      <h1> Masters Results </h1>
      <p>
        Loaded periodically from
        {' '}
        {/* eslint-disable-next-line react/jsx-no-target-blank */ }
        <a target="_blank" href="https://www.pgatour.com/leaderboard">pgatour.com</a>
      </p>
      <p>
        last update:
        {' '}
        {date.toLocaleTimeString()}
        {' - '}
        {date.toDateString()}
      </p>
      <h2>Current Standings</h2>
      {scores.map((contestantScores) => (
        <div key={contestantScores.name}>
          <p>
            {contestantScores.name}
            {': '}
            {contestantScores.total}
          </p>
        </div>
      ))}
      <hr />
      <h2>Detailed Standings</h2>
      {scores.map((contestantScores) => (
        <div key={contestantScores.name}>
          <h3>{contestantScores.name}</h3>
          <p>{contestantScores.total}</p>
          <div>
            {contestantScores.scores.map((score, index) => (
              <div key={score.name} style={{ fontWeight: `${index < 4 ? 'normal' : '100'}` }}>
                <p>
                  {`${score.name} [${score.tier}]`}
                </p>
                <p>
                  Current round through
                  {' '}
                  {score.through}
                  {': '}
                  {score.roundScore}
                </p>
                <div style={{ display: 'flex', width: '300px', justifyContent: 'space-between' }}>
                  <div>
                    {Number.isNaN(score.round1) ? '-' : score.round1}
                  </div>
                  <div>
                    {Number.isNaN(score.round2) ? '-' : score.round2}
                  </div>
                  <div>
                    {Number.isNaN(score.round3) ? '-' : score.round3}
                  </div>
                  <div>
                    {Number.isNaN(score.round4) ? '-' : score.round4}
                  </div>
                  <div style={{ border: 'solid black 1px', padding: '2px' }}>
                    {score.total}
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
