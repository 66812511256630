import React, { useState } from 'react';

export function MessageSubmit(props: { onSubmit: (name: string, message: string) => void }) {
  const { onSubmit } = props;
  const [currentError, setCurrentError] = useState<string | undefined>(undefined);
  const onLocalSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);
    const name = formData.get('name') as string;
    const message = formData.get('message') as string;

    if (!name || !message) {
      setCurrentError('Please enter a name and message');
      return;
    }

    setCurrentError(undefined);
    form.reset();
    onSubmit(name, message);
  };
  // FIXME CSS is off now that labels are containing the inputs as required by eslint
  return (
    <div className="message-board-posting">
      <div className="error">{currentError}</div>
      <form onSubmit={onLocalSubmit}>
        {/* lint rule requires label is nested as well */ }
        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="name">
          Name:
        </label>
        <input type="text" name="name" id="name" />
        {/* lint rule requires label is nested as well */ }
        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="message">
          Message:
        </label>
        <textarea name="message" id="message" />
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
}
