import { tourneyId } from './utilities';

export enum DiscordChannel {
    ERRORS = 'https://discord.com/api/webhooks/1218586048641110057/Cm0ru7C9koUszryXWF3svazKPT9JT8geGSPNEhX78e9RluhPjNLsI09G3wd1TW8_S_4E',
    REPORT_ISSUE = 'https://discord.com/api/webhooks/1218585948141518879/njcu2hDJX8_QQUCOYLkitB1SqQo5GPgVgSfBHBpLMRrGsAcP_3w9Bqzde46r9erRpXaA',
    STATUS = 'https://discord.com/api/webhooks/1218585712757309460/RmD0JO809l_upjFKSgfmPllE7iLCgsba5JOWAWxm_z7wIt2OmxhsET13Y5KEbh-OD9fR',
    BOT_TESTING = 'https://discord.com/api/webhooks/1079102333218209792/zNS-bINDMqGgmQFaj3fKuJkNd2ruTdL8Hu6qFWnk0tEc1YAzohW4_hlKczyIANOzVlvT',
    MOES = 'https://discord.com/api/webhooks/1198301847774040225/Tz4gXQb_SBtu7nlcezBLLs_W9w4y0uc-y-Htpka6q-jXbrgmmXbgZ0klrNxFSNEW_zep',
    BOOSKETBALL = 'https://discord.com/api/webhooks/1203818728412811325/KjmTIn5rYoyHQT_YF9SbbSJbipgi59AB5Js18E6brZ1LilHUNyUr9geMABziG_kHazd6'
}

// TODO add some rate limiting? Especially for error channel

let lastMessage: string | undefined;

export async function postToDiscord(message: string, channel: DiscordChannel): Promise<void> {
  if (message === lastMessage) {
    console.warn('skipping duplicate message');
    return;
  }
  lastMessage = message;

  const data = {
    content: `[${tourneyId()}] ${message}` // add tourney year/id here
  };

  try {
    await fetch(channel, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  } catch (error) {
    console.error(error);
  }
}
